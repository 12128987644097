import React, { useState, useEffect, useContext, useRef } from 'react';
import clsx from 'clsx';
import '../App.css';
import '../css/admin-booking.css';
import axios from 'axios';
import { API } from '../hooks/api';
import {
    makeStyles,
    Dialog,
    Box,
    IconButton,
    SvgIcon,
    useMediaQuery,
    useTheme,
    Menu,
    MenuItem,
} from '@material-ui/core';
import Loading from '../components/Loading';
import { dateExport, getAccess, getAppVersion, getDevice, humanExpireTime } from './helpper';
import {
    X as CloseIcon,
    Edit as EditIcon,
    Search as SearchIcon,
    User as UserIcon,
    Filter as FilterIcon,
    ChevronDown as ChevronDownIcon,
    RefreshCw as RefreshCwIcon,
} from 'react-feather';
import ErrorDialog from './ErrorDialog';
import SuccessDialog from './SuccessDialog';
import ConfirmDialog from './ConfirmDialog';
import moment from 'moment';
import ReactExport from "react-export-excel";

import DatePicker from 'react-datetime';
// import 'react-datetime/css/react-datetime.css';
import '../css/react-datetime.css';

import DayPicker from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';

import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parse';

function parseDate(str, format, locale) {
//   const parsed = dateFnsParse(str, format, new Date(), { locale });
//   console.log(format, str, locale);
//   console.log(parsed);
//   if (DateUtils.isDate(parsed)) {
//     return parsed;
//   }
//   return undefined;
    let splits = str.split('/');
    let date = new Date(parseInt(splits[2]), parseInt(splits[1]) - 1, parseInt(splits[0]));
    return date;
}

function formatDate(date, format, locale) {
  return dateFnsFormat(date, format, { locale });
}

const FORMAT_DATE = 'DD/MM/YYYY';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: '#FFF',
        minHeight: '100vh',
    },
    container2: {
        padding: '7px 20px',
        display: 'flex',
        justifyContent: 'center',
        // marginTop: 30,
    },
    container3: {
        padding: '7px 20px',
        display: 'flex',
        justifyContent: 'flex-start',
    },
    container4: {
        padding: '7px 20px',
    },
    box: {
        width: '100%',
        maxWidth: 1400,
        display: 'flex',
        overflowX:'auto',
        // overflow: 'auto',
        // [theme.breakpoints.down('md')]: {
        //     overflowX:'scroll'
        // }
        '&::-webkit-scrollbar': {
            width: '0.3em',
            backgroundColor: '#E7E8E9',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#D3D2D2',
        }
    },
    box2: {
        width: '100%',
        maxWidth: 1400,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 10,
    },
    box3: {
        maxWidth: 1400,
        display: 'flex',
        marginBottom: 10,
        paddingLeft: 40,
        [theme.breakpoints.down('md')]: {
            paddingLeft: 0,
        }
    },

    detail: {
        fontSize: 14,
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        maxWidth: 1400,
    },

    topbar: {
        background: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '15px 30px',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
        [theme.breakpoints.down('xs')]: {
            padding: '15px 15px',
        }
    },
    topbarTitle: {
        fontSize: 29,
    },
    topbarTitle2: {
        fontSize: 14,
        alignItems: 'center',
        display: 'flex',
        cursor: 'pointer'
    },
    menuItem: {
        fontSize: 14,
        fontFamily: 'myFont',
        width: 150,
    },
    menuItem2: {
        fontSize: 14,
        fontFamily: 'myFont',
        // width: 150,
    },
    accountIcon: {
        fontSize: 18,
        marginLeft: 10,
    },

    table: {
        width: '100%',
        fontSize: 14,
        minWidth: 1400,
    },
    th: {
        fontSize: 14,
        color: 'white',
        padding: '8px'
    },
    icon: {
        color: '#000',
        fontSize: 14,
        cursor: 'pointer',
    },
    icon2: {
        color: '#000',
        fontSize: 14,
        cursor: 'pointer',
        marginLeft: 5
    },

    dialog: {
        padding: 20,
    },
    dialogTitle: {
        fontSize: 16,
        fontWeight: 600,
    },
    boxIcon: {
        position: 'absolute',
        top: 8,
        right: 10,
    },
    closeIcon: {
        color: '#000',
        fontSize: 20,
    },
    dialogContent: {
        padding: '20px 10px 10px 10px',
    },

    redeemBox: {
        padding: '8px 0px',
        display: 'flex',
        flexGrow: 1,
    },
    redeemHeader: {
        padding: '8px 6px',
        width: '40%',
        textAlign: 'right',
        fontSize: 14,
    },
    redeemDetail: {
        fontSize: 14,
        padding: '0px 6px',
        width: '60%',
    },
    redeemDetail2: {
        fontSize: 14,
        padding: '0px 6px',
        width: '40%',
    },
    redeemDetail3: {
        padding: '8px 6px',
        width: '15%',
        fontSize: 14,
    },
    redeemRadio: {
        padding: '8px 6px',
        width: '60%',
        fontSize: 12,
    },
    req: {
        color: 'red',
        marginLeft: 2
    },
    formFieldError: {
        color: 'red',
        textIndent: '5px',
        fontSize: 11,
        marginTop: 3,
    },
    formFieldInput: {
        backgroundColor: '#FAFAFA',
        borderRadius: '2px',
        border: '1px solid #CCCCCC',
        boxSizing: 'border-box',
        outline: 'none',
        fontFamily: 'myFont',
        width: '100%',
        fontSize: 12,
        height: 30,
        padding: '0 10px',
        '&:focus': {
            boxShadow: 'inset 0 0 0px 2px #69cdb3',
        },
    },
    formFieldInput2: {
        backgroundColor: '#FAFAFA',
        borderRadius: '2px',
        border: '1px solid #CCCCCC',
        boxSizing: 'border-box',
        outline: 'none',
        fontFamily: 'myFont',
        width: '100%',
        fontSize: 12,
        height: 30,
        padding: '0 10px',
        '&:focus': {
            boxShadow: 'inset 0 0 0px 2px #69cdb3',
        },
        '&:disabled': {
            background: '#C8C8C8',
        }
    },
    formFieldSelect: {
        backgroundColor: '#FAFAFA',
        borderRadius: '2px',
        border: '1px solid #CCCCCC',
        boxSizing: 'border-box',
        outline: 'none',
        fontFamily: 'myFont',
        width: 200,
        fontSize: 12,
        height: 30,
        padding: '0 10px',
        '&:focus': {
            boxShadow: 'inset 0 0 0px 2px #69cdb3',
        },
    },

    blockBtn: {
        marginTop: 10,
        // display: 'flex',
        textAlign: 'center',
        justifyContent: 'center',
    },
    actionRedeem: {
        background: '#000',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        textDecoration: 'none',

        fontSize: 16,
        height: 36,
        width: 100,
        borderRadius: '50px',
        margin: 'auto auto 2px',
        cursor: 'pointer',
    },
    actionRedeemDisabled: {
        // opacity: 0.5,
        background: 'rgba(0,0,0,0.5)'
    },

    actionAdd: {
        background: '#6DCDB1',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#000',
        fontSize: 18,
        height: 60,
        width: 200,
        borderRadius: '10px',
        cursor: 'pointer',
        border: 'none'
    },

    formSearch: {
        backgroundColor: '#FAFAFA',
        borderRadius: '2px',
        border: '1px solid #CCCCCC',
        boxSizing: 'border-box',
        outline: 'none',
        fontFamily: 'myFont',
        width: 300,
        fontSize: 12,
        height: 36,
        padding: '0 10px',
        '&:focus': {
            boxShadow: 'inset 0 0 0px 2px #69cdb3',
        },
    },
    formSearchSelect: {
        backgroundColor: '#FAFAFA',
        borderRadius: '2px',
        border: '1px solid #CCCCCC',
        boxSizing: 'border-box',
        outline: 'none',
        fontFamily: 'myFont',
        width: '100%',
        fontSize: 13,
        height: 36,
        padding: '0 10px',
        '&:focus': {
            boxShadow: 'inset 0 0 0px 2px #69cdb3',
        },
    },
    searchIcon: {
        color: '#000',
        fontSize: 24,
        marginLeft: 10,
    },

    boxOrder: {
        background: '#F4F7F6',
        border: '1px solid #DEDEDE',
        boxSizing: 'border-box',
        padding: '10px 30px',
        fontSize: 18,
        width: 170,
        display: 'flex',
        flexGrow: 1,
        alignItems: 'center',
        cursor: 'pointer'
    },
    boxOrder2: {
        background: '#F4F7F6',
        border: '1px solid #DEDEDE',
        boxSizing: 'border-box',
        padding: '10px 30px',
        fontSize: 14,
        width: 180,
        display: 'flex',
        flexGrow: 1,
        alignItems: 'center',
        cursor: 'pointer',
        marginTop: 30,
    },
    boxDate: {
        background: '#F4F7F6',
        border: '1px solid #DEDEDE',
        boxSizing: 'border-box',
        padding: '10px 30px',
        fontSize: 18,
        width: 120,
        display: 'flex',
        flexGrow: 1,
        alignItems: 'center',
        marginLeft: 5,
        cursor: 'pointer'
    },
    dateIcon: {
        color: '#000',
        fontSize: 18,
        marginLeft: 10,
    },
    filterIcon: {
        color: '#000',
        fontSize: 18,
        marginRight: 10,
        [theme.breakpoints.down('xs')]: {
            fontSize: 12,
        }
    },
    popover: {
        padding: 0
    },

    paging: {
        background: 'white',
        color: '#000',
        borderRadius: '10px',
        cursor: 'pointer',
        marginLeft: 5,
        fontSize: 16,
        padding: '3px 8px',
        border: '1px solid #000',
        fontWeight: 600,
    },
    pagingActive: {
        background: '#1498E6',
        color: 'white',
        borderRadius: '10px',
        cursor: 'pointer',
        marginLeft: 5,
        fontSize: 16,
        padding: '3px 8px',
        border: '1px solid #1498E6',
        fontWeight: 600,
    },
    formDate: {
        backgroundColor: '#FAFAFA',
        borderRadius: '2px',
        border: '1px solid #CCCCCC',
        boxSizing: 'border-box',
        outline: 'none',
        fontFamily: 'myFont',
        // width: '100%',
        fontSize: 13,
        height: 36,
        padding: '0 10px',
        '&:focus': {
            boxShadow: 'inset 0 0 0px 2px #69cdb3',
        },
    },

    formDatePicker: {
        '&:input': {
            padding: 20
        }
    },
}));

const initCriteria = {
    apptoken: '',
    searchtext: '',
    searchservicedatebegin: '',
    searchservicedateend: '',
    searchservicestatus: '',
    searchmodel: '',
    searchserviceid: '',
    // sortby: 'servicedate', // customername, servicedate, ''
    // orderby: 'desc', // desc, asc
    sortby: '',
    orderby: 'asc',
    page: '1',
    pagesize: '30'
}

const AdminBooking = () => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const apptoken = window.localStorage.getItem('apptoken');

    const [info, setInfo] = useState({});
    const [item, setItem] = useState([]);
    const [result, setResult] = useState([]);
    const [resultExport, setResultExport] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);

    const [criteria, setCriteria] = useState({ ...initCriteria });

    const theme = useTheme();
    const smDown = useMediaQuery(theme.breakpoints.down('xs'));

    useEffect(() => {
        if (apptoken) {
            checkToken();
        } else {
            window.location = '/login';
        }
    }, [])

    const checkToken = async () => {
        axios.defaults.headers.common.Authorization = `Bearer ${apptoken}`;

        const response = await axios.post(API.ADMIN_CHECK_TOKEN, {
            apptoken: apptoken,
        });

        if (response.data.result === 'complete') {
            getInfo();
        } else {
            localStorage.removeItem('apptoken');
            delete axios.defaults.headers.common.Authorization;
            window.location = '/login';
        }
    }

    const getInfo = async () => {
        const response = await axios.post(API.ADMIN_CHECK_INFO, {
            apptoken: apptoken,
        });
        // console.log("get info", response.data);
        setInfo({ ...response.data });

        let isAdmin = response.data.role === 'superadmin' || response.data.role === 'callcenter';

        let _criteria = {
            ...criteria,
            // sortby: !isAdmin ? 'servicedate' : '',
            // orderby: !isAdmin ? 'desc' : 'asc',
            sortby: '',
            orderby: 'asc',
        }
        setCriteria({ ..._criteria });
        getResult({ ..._criteria });

        setIsAdmin(isAdmin);
    }

    const getResult = async (criteria) => {
        const response = await axios.post(API.ADMIN_BOOKING_LIST, {
            ...criteria,
            apptoken: apptoken
        });
        console.log("result", response.data);

        const responseExport = await axios.post(API.ADMIN_BOOKING_LIST, {
            ...criteria,
            apptoken: apptoken,
            page: '1',
            pagesize: (response.data.totalbooking_search || '10') + ''
        });

        setItem(response.data);
        setResult(response.data.list || []);
        setResultExport(responseExport.data.list || []);
    }

    const setStatus = (status) => {
        if (status === 'done')
            return 'Done'
        if (status === 'waiting_booking')
            return 'Waiting Booking'
        if (status === 'booking')
            return 'Booking'
        return status
    }

    const [openUpdate, setOpenUpdate] = useState(false);
    const [openCreate, setOpenCreate] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [openComplete, setOpenComplete] = useState(false);
    const [formData, setFormData] = useState({});

    const [openError, setOpenError] = useState(false);
    const [textError, setTextError] = useState();

    const [openSuccess, setOpenSuccess] = useState(false);
    const [textSuccess, setTextSuccess] = useState();

    const onCloseDialog = () => {
        setFormData({});
        setOpenUpdate(false);
        setOpenComplete(false);
        setOpenCreate(false);
        setOpenEdit(false);
    }

    // - - - - - - - Create - - - - - - -
    const onClickOpenCreate = () => {
        setOpenCreate(true);
        setFormData({});
    }

    const onSaveCreate = async (formData) => {
        setLoading(true);
        const response = await axios.post(API.ADMIN_BOOKING_CREATE, {
            apptoken: apptoken,
            ...formData,
        });
        console.log("create", response.data);

        setLoading(false);
        let data = response?.data;
        if (data?.result === 'error') {
            if (data?.detail === 'cannot_booking') {
                setTextError('ไม่สามารถจองเวลาได้');
            } else if (data?.detail === 'cannot_register') {
                setTextError('ไม่สามารถลงทะเบียนได้');
            } else if (data?.detail === 'invalid_serviceid') {
                setTextError('ศูนย์บริการไม่ถูกต้อง')
            }  else if (data?.detail === 'jobdone') {
                setTextError('ไม่สามารถแก้ไขได้ เนื่องจากบันทึกการเข้ารับบริการแล้ว')
            } else {
                setTextError('ไม่สามารถแก้ไขวัน-เวลาได้')
            }
            setOpenError(true);
        } else {
            getResult({ ...criteria });
            onCloseDialog();
            setTextSuccess(`ลงทะเบียนสำเร็จ ${data?.refid}`);
            setOpenSuccess(true);
        }
    }

    // - - - - - - - Update / Edit - - - - - - -
    const onClickUpdate = (data) => {
        setFormData({ ...data });
        setOpenUpdate(true);
    }

    const onClickOpenEdit = (data) => {
        setFormData({ ...data });
        setOpenEdit(true);
    }

    const onSaveEdit = async (formData, isEdit) => {
        setLoading(true);
        const response = await axios.post(API.ADMIN_BOOKING_EDIT, {
            apptoken: apptoken,
            ...formData,
        });
        console.log("edit", response.data);

        setLoading(false);
        let data = response?.data;
        if (data?.result === 'error') {
            if (data?.detail === 'cannot_booking') {
                setTextError('ไม่สามารถจองเวลาได้');
            } else if (data?.detail === 'cannot_register') {
                setTextError('ไม่สามารถลงทะเบียนได้');
            } else if (data?.detail === 'invalid_serviceid') {
                setTextError('ศูนย์บริการไม่ถูกต้อง')
            } else if (data?.detail === 'jobdone') {
                setTextError('ไม่สามารถแก้ไขได้ เนื่องจากบันทึกการเข้ารับบริการแล้ว')
            } else {
                setTextError('ไม่สามารถแก้ไขวัน-เวลาได้')
            }
            setOpenError(true);
        } else {
            getResult({ ...criteria });
            onCloseDialog();
            setTextSuccess(isEdit ? 'แก้ไขข้อมูลผู้ใช้บริการสำเร็จ' : 'แก้ไขการเข้ารับบริการสำเร็จ');
            setOpenSuccess(true);
        }
    }

    // - - - - - - - Complete - - - - - - -
    const onClickOpenComplete = (data) => {
        setFormData({ ...data });
        setOpenComplete(true);
    }

    const onSaveComplete = async (formData) => {
        setLoading(true);
        const response = await axios.post(API.ADMIN_STATUS_COMPLETE, {
            apptoken: apptoken,
            customerid: formData.customerid
        });
        console.log("complete", response.data);

        setLoading(false);
        let data = response?.data;
        if (data?.result === 'error') {
            setTextError('ยืนยันเข้ารับการซ่อมไม่สำเร็จ')
            setOpenError(true);
        } else {
            getResult({ ...criteria });
            onCloseDialog();
            setTextSuccess('ยืนยันเข้ารับการซ่อมสำเร็จ');
            setOpenSuccess(true);
        }
    }

    // - - - - - - - Search - - - - - - -
    const onClickSearchResult = (criteria) => {
        setCriteria(criteria);
        getResult(criteria);
    }

    const onClickClearSearch = () => {
        let _criteria = {
            ...criteria,
            searchtext: '',
            searchservicedatebegin: '',
            searchservicedateend: '',
            searchservicestatus: '',
            searchmodel: '',
            searchserviceid: '',
        }
        setCriteria(_criteria);
        getResult(_criteria);
    }

    const exportExcel = (<>
        <ExcelFile filename={`Admin Booking`} element={
          <button style={{ fontSize: 14 }}>Export</button>
        }>
          <ExcelSheet data={resultExport} name="Admin Booking">
            <ExcelColumn label="เลขที่ลงทะเบียน" value="refid" />
            <ExcelColumn label="ชื่อ - นามสกุล" value="customername" />
            <ExcelColumn label="เบอร์โทร" value="mobile" />
            <ExcelColumn label="หมายเลขตัวถัง" value="framenumber" />
            <ExcelColumn label="รุ่นรถ" value="model" />
            <ExcelColumn label="ระยะทาง (กม.)" value="milage" />
            <ExcelColumn label="ทะเบียนรถ" value="vehicleregisternumber" />
            <ExcelColumn label="Part" value={(value) => value.sparepartlist.join(', ')} />
            <ExcelColumn label="ศูนย์บริการ" value="facade" />
            <ExcelColumn label="วันเข้ารับบริการ" value={(value) => dateExport(value.servicedate)} />
            <ExcelColumn label="เวลา" value="servicetime" />
            <ExcelColumn label="status เข้าอัพเกรด" value={(value) => setStatus(value.status)} />
          </ExcelSheet>
        </ExcelFile>
    </>)

    return (<>
        <div className={classes.container}>
            {loading && <Loading />}
            <Topbar info={info} />
            
            <UpdateServiceDialog
                open={openUpdate}
                onClose={onCloseDialog}
                data={formData}
                onSaveEdit={onSaveEdit}
                isAdmin={isAdmin}
            />

            <EditDialog
                open={openEdit}
                onClose={onCloseDialog}
                data={formData}
                onSaveEdit={onSaveEdit}
            />

            <CompleteDialog
                open={openComplete}
                onClose={onCloseDialog}
                data={formData}
                onSaveComplete={onSaveComplete}
            />

            <CreateDialog
                open={openCreate}
                onClose={onCloseDialog}
                onSaveCreate={onSaveCreate}
            />

            <ErrorDialog
                open={openError}
                text={textError}
                onClose={() => setOpenError(false)}
            />

            <SuccessDialog
                open={openSuccess}
                text={textSuccess}
                onClose={() => setOpenSuccess(false)}
            />
            
            {isAdmin ? (
                <div className={classes.container2} style={{ marginTop: 30 }}>
                    <div className={classes.box2}>
                        <div style={{ justifyContent: 'flex-start' }}>
                            <button
                                className={classes.actionAdd}
                                onClick={onClickOpenCreate}
                            >+ เพิ่มการจอง</button>
                        </div>
                        <div style={{ justifyContent: 'flex-end' }}>
                            <div className={classes.boxOrder2}
                                onClick={onClickClearSearch}
                            >
                                <SvgIcon className={classes.filterIcon}>
                                    <RefreshCwIcon />
                                </SvgIcon>
                                ล้างการค้นหา
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className={classes.container2}>
                    <div className={classes.box2}>
                        <div style={{ justifyContent: 'flex-start' }}>
                            
                        </div>
                        <div style={{ justifyContent: 'flex-end' }}>
                            <div className={classes.boxOrder2}
                                onClick={onClickClearSearch}
                            >
                                <SvgIcon className={classes.filterIcon}>
                                    <RefreshCwIcon />
                                </SvgIcon>
                                ล้างการค้นหา
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {smDown ? (
                <div className={classes.container4} style={{ marginTop: isAdmin ? 0 : 0 }}>
                    <div>
                        <div style={{ display: 'inline-block' }}>
                            <FillterByCriteria
                                searchResult={onClickSearchResult}
                                criteria={criteria}
                            />
                        </div>
                        {/* <div style={{ display: 'inline-block' }}>
                            <DateCriteria
                                searchResult={onClickSearchResult}
                                criteria={criteria}
                            />
                        </div> */}
                    </div>
                    <div style={{ marginTop: 10 }}>
                        <SearchCriteria
                            searchResult={onClickSearchResult}
                            criteria={criteria}
                        />
                    </div>
                </div>
            ) : (
                <div className={classes.container2} style={{ marginTop: isAdmin ? 0 : 0 }}>
                    <div className={classes.box2}>
                        <div style={{ justifyContent: 'flex-start' }}>
                            <div style={{ display: 'flex', flexGrow: 1 }}>
                                <FillterByCriteria
                                    searchResult={onClickSearchResult}
                                    criteria={criteria}
                                />
                                {/* <DateCriteria
                                    searchResult={onClickSearchResult}
                                    criteria={criteria}
                                />   */}
                            </div>
                        </div>

                        <div style={{ justifyContent: 'flex-end' }}>
                            <SearchCriteria
                                searchResult={onClickSearchResult}
                                criteria={criteria}
                            />
                        </div>
                    </div>
                </div>
            )}

            <div className={classes.container2}>
                <div className={classes.box2}>
                    <div style={{ justifyContent: 'flex-start' }}>
                        {exportExcel}
                    </div>
                    <div style={{ justifyContent: 'flex-end', fontSize: 14 }}>
                        จำนวนรถที่เข้ารับบริการ <span style={{ color: 'red', marginLeft: 5, marginRight: 10 }}>{item.totalbooking_search || 0} คัน</span>
                        เข้าซ่อมเรียบร้อยแล้ว <span style={{ color: 'red', marginLeft: 5 }}>{item.totaldone_search || 0} คัน</span>
                    </div>
                </div>
            </div>

            <div className={classes.container2}>
                <div className={classes.box}>
                    <table className={classes.table}>
                        <tr>
                            {isAdmin && <th className={classes.th}>แก้ไข</th>}
                            <th className={classes.th}>เลขที่ลงทะเบียน</th>
                            <th className={classes.th}>ชื่อ - นามสกุล</th>
                            <th className={classes.th}>เบอร์โทร</th>
                            <th className={classes.th}>หมายเลขตัวถัง</th>
                            <th className={classes.th}>รุ่นรถ</th>
                            <th className={classes.th}>ระยะทาง (กม.)</th>
                            <th className={classes.th}>ทะเบียนรถ</th>
                            <th className={classes.th}>Part</th>
                            <th className={classes.th}>ศูนย์บริการ</th>
                            <th className={classes.th}>วันเข้ารับบริการ</th>
                            <th className={classes.th}>เวลา</th>
                            <th className={classes.th}>status เข้าอัพเกรด</th>
                        </tr>
                        {result.length === 0 && (
                            <tr>
                               <td colSpan={isAdmin ? 12: 11} style={{ padding: 8 }}>ไม่พบรายการ</td> 
                            </tr>
                        )}
                        {result.map((m, i) => (
                            <tr key={i}>
                                {isAdmin && (
                                <td>
                                    {m.status !== 'done' && (
                                        <SvgIcon className={classes.icon2} onClick={() => onClickOpenEdit(m)}>
                                            <EditIcon />
                                        </SvgIcon>
                                    )}
                                </td>
                                )}
                                <td>{m.refid}</td>
                                <td>{m.customername}</td>
                                <td>{m.mobile}</td>
                                <td>{m.framenumber}</td>
                                <td>{m.model}</td>
                                <td>{m.milage}</td>
                                <td>{m.vehicleregisternumber}</td>
                                <td>
                                    {(m?.sparepartlist||[]).map(((m, i) => (
                                        <p>- {m}</p>
                                    )))}
                                </td>
                                <td>
                                    <>
                                        {m.facade}
                                        {m.status !== 'done' && isAdmin && (
                                            <SvgIcon className={classes.icon2} onClick={() => onClickUpdate(m)}>
                                                <EditIcon />
                                            </SvgIcon>
                                        )}
                                    </>
                                </td>
                                <td>
                                    {(!m.servicedate && m.status !== 'done') ? (
                                        <SvgIcon className={classes.icon} onClick={() => onClickUpdate(m)}>
                                            <EditIcon />
                                        </SvgIcon>
                                    ) : (
                                        <>
                                            {humanExpireTime(m.servicedate)}
                                            {m.status !== 'done' && (
                                                <SvgIcon className={classes.icon2} onClick={() => onClickUpdate(m)}>
                                                    <EditIcon />
                                                </SvgIcon>
                                            )}
                                        </>
                                    )}
                                </td>
                                <td>
                                    {(!m.servicetime && m.status !== 'done') ? (
                                        <SvgIcon className={classes.icon} onClick={() => onClickUpdate(m)}>
                                            <EditIcon />
                                        </SvgIcon>
                                    ) : (
                                        <>
                                            {m.servicetime}
                                            {m.status !== 'done' && (
                                                <SvgIcon className={classes.icon2} onClick={() => onClickUpdate(m)}>
                                                    <EditIcon />
                                                </SvgIcon>
                                            )}
                                        </>
                                    )}
                                </td>
                                <td>
                                    <>
                                        {setStatus(m.status)}
                                        {m.status === 'booking' && (
                                            <SvgIcon className={classes.icon2} onClick={() => onClickOpenComplete(m)}>
                                                <EditIcon />
                                            </SvgIcon>
                                        )}
                                    </>
                                </td>
                            </tr>
                        ))}
                    </table>
                </div>
            </div>

            <div className={classes.container2}>
                <div className={classes.detail}>
                    <Paging 
                        item={item}
                        searchResult={onClickSearchResult}
                        criteria={criteria}
                    />
                </div>
            </div>
        </div>
    </>)
}

export default AdminBooking;

const Paging = ({
    item,
    searchResult,
    criteria,
}) => {

    const [pageList, setPageList] = useState([]);
    const [page, setPage] = useState(0);
    const classes = useStyles();

    useEffect(() => {
        let page = parseInt(criteria.page) || 1;
        let totalpage = parseInt(item.totalpage) || 0;

        let start = page - 1;
        let end = page + 1;

        if (start <= 0) {
            start = 1;
        }

        if (end > totalpage) {
            end = totalpage;
        }

        if (start === 1 && totalpage >= 3) {
            end = 3;
        }

        if (end === totalpage && ((end - start) === 1)) {
            start = start - 1;
        }

        if (start <= 0) {
            start = 1;
        }

        let pageList = [];
        for(var i = start; i <= end; i++) {
            pageList.push(i);
        }

        if (totalpage) {
            setPageList(pageList);
            setPage(page);
        } else {
            setPageList([]);
            setPage(0);
        }
    }, [item])

    const onClickPage = (page) => {
        searchResult({
            ...criteria,
            page: page + "",
        });
    }

    return (
        <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-end' }}>
            {pageList.map(m => (
                <button
                    className={page === m ? classes.pagingActive : classes.paging}
                    onClick={() => onClickPage(m)}
                >{m}</button>
            ))}
        </div>
    )
}

const Topbar = ({
    info,
}) => {
    const classes = useStyles();
    const [isOpen, setOpen] = useState(false);
    const ref = useRef(null);

    const handleLogout = () => {
        localStorage.removeItem('apptoken');
        delete axios.defaults.headers.common.Authorization;
        window.location = '/login';
    }

    return (
        <div className={classes.topbar}>
            <div className={classes.topbarTitle}>{info?.name || ''}</div>

            <div
                className={classes.topbarTitle2}
                onClick={() => setOpen(true)}
                ref={ref}
            >
                {'Vespa Admin'}
                <SvgIcon className={classes.accountIcon}>
                    <UserIcon />
                </SvgIcon>
            </div>
            <Menu
                onClose={() => setOpen(false)}
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
                }}
                keepMounted
                PaperProps={{ className: classes.popover }}
                getContentAnchorEl={null}
                anchorEl={ref.current}
                open={isOpen}
            >
                <MenuItem onClick={handleLogout} className={classes.menuItem}>
                    ออกจากระบบ
                </MenuItem>
            </Menu>
        </div>
    );
};

const UpdateServiceDialog = ({
    open,
    onClose,
    onSaveEdit,
    data,
    isAdmin,
}) => {
    const classes = useStyles();
    const [formData, setFormData] = useState({});
    const [formBlur, setFormBlur] = useState({});
    const [disabledBtn, setDisabled] = useState(true);

    useEffect(() => {
        if (open) {
            let servicedate = data.servicedate;
            if (servicedate) {
                servicedate = servicedate.split(' ')[0] || '';
            }
            setServiceTimeList([]);
            setFormData({
                ...data,
                servicedate: '',
                servicetime: '',
            });
            getServiceAll();
        }
    }, [open])

    const onChangeInput = (e) => {
        let { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        })
        setFormBlur({
            ...formBlur,
            [name]: true
        })

        if (name === 'servicedate') {
            setFormData({
                ...formData,
                [name]: value,
                servicetime: '',
            })
        }
    }

    const onChangeInputService = (e) => {
        let { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
            // servicedate: '',
            servicetime: '',
        })
    }

    useEffect(() => {
        if (formData.serviceid) {
            getServiceDate(formData.serviceid);
        }

        if (formData.servicedate && formData.serviceid) {
            getServiceTime(formData.serviceid, formData.servicedate);
        }

        if (!formData.servicedate || !formData.servicetime) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [formData])

    const [serviceTimeList, setServiceTimeList] = useState([]);

    const getServiceTime = async (serviceid, servicedate) => {
        const dateFormat = moment(servicedate).format('yyyy-MM-DD');

        const response = await axios.post(API.GET_SERVICE_TIME, {
            serviceid: serviceid,
            servicedate: dateFormat
        });

        let list = response.data.servicetime || [];
        setServiceTimeList(list.map(m => m.time));
        if (!list.length) {
            alert('จำนวนการจองเต็ม / วันหยุดศูนย์บริการ');
        }
    }

    const onChangeTime = (value) => {
        setFormData({
            ...formData,
            servicetime: value
        })
    }

    const onSubmit = () => {
        setOpenConfirm(true);
    }

    const [serviceAllList, setServiceAllList] = useState([]);

    const getServiceAll = async (serviceList) => {
        const response = await axios.post(API.GET_SERVICE_LIST, {});
        let list = response.data.dealer || [];
        setServiceAllList(list);
    }

    const [openConfirm, setOpenConfirm] = useState(false);

    const onSave = () => {
        setOpenConfirm(false);
        let servicedate = moment(formData.servicedate).format('yyyy-MM-DD');
        onSaveEdit({ 
            ...formData,
            servicedate: servicedate,
            access: getAccess(),
            appversion: getAppVersion(),
            osversion: '',
            device: getDevice(),
            deviceid: '',
        });
    }

    const onCloseConfirm = () => {
        setOpenConfirm(false);
        onClose();
    }

    const onChangeDate = (day) => {
        setFormData({
            ...formData,
            servicedate: day,
        })
    }

    const [maxDate, setMaxDate] = useState();
    const [disabledDateList, setDisabledDateList] = useState([]);

    const getServiceDate = async (serviceid) => {
        const response = await axios.post(API.GET_SERVICE_DATE, {
            serviceid: serviceid,
        });
        
        let list = response.data.servicedate || [];
        let maxDate = '';
        if (list.length) {
            maxDate = list[list.length - 1];
        }

        let dateMax = null;
        if (maxDate) {
            let splits = maxDate.split('-');
            dateMax = new Date(parseInt(splits[0]), parseInt(splits[1]) - 1, parseInt(splits[2]));
        }
        if (dateMax) {
            setMaxDate(dateMax);
        }

        const response2 = await axios.post(API.GET_SERVICE_DATE_DIS, {
            serviceid: serviceid,
        });
        let listDisabled = [];
        let listdate = response2.data.unavailabledate || [];
        listdate.map(m => {
            let splits = m.split('-');
            let date = new Date(parseInt(splits[0]), parseInt(splits[1]) - 1, parseInt(splits[2]));
            listDisabled.push(date);
        })
        setDisabledDateList(listDisabled);
    }

    return (<>
        <ConfirmDialog
            open={openConfirm}
            onClose={onCloseConfirm}
            onOk={onSave}
        />

        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="xs"
            fullWidth
        >
            <div className={classes.dialog}>
                <Box align="right" className={classes.boxIcon}>
                    <IconButton onClick={onClose}>
                        <SvgIcon className={classes.closeIcon}>
                            <CloseIcon />
                        </SvgIcon>
                    </IconButton>
                </Box>

                <div className={classes.dialogContent}>
                    <div className={classes.dialogTitle} style={{ marginBottom: 20 }}>
                        แก้ไขการเข้ารับบริการ
                    </div>

                    <div className={classes.redeemBox}>
                        <div className={classes.redeemHeader}>
                            ศูนย์บริการ
                        </div>
                        <div className={classes.redeemDetail}>
                            <div>
                                {isAdmin ? (
                                    <select
                                        // className={classes.formFieldSelect}
                                        className='minimal'
                                        value={formData?.serviceid}
                                        onChange={onChangeInputService}
                                        name="serviceid"
                                    >
                                        <option hidden>เลือกศูนย์บริการ</option>
                                        {serviceAllList.map(m => (
                                            <option value={m.serviceid} key={m.serviceid}>{m.facade}</option>
                                        ))}
                                    </select>
                                ) : (
                                    <input className={classes.formFieldInput2} 
                                        type="text"
                                        name="email"
                                        value={formData.facade}
                                        disabled={true}
                                    />
                                )}
                            </div>
                        </div>
                    </div>

                    <div className={classes.redeemBox}>
                        <div className={classes.redeemHeader}>
                            วันที่เข้ารับบริการ<span className={classes.req}>*</span>
                        </div>
                        <div className={classes.redeemDetail}>
                            {/* <input type="date"
                                name="servicedate"
                                value={formData?.servicedate}
                                onChange={onChangeInput}
                                min={moment().format('YYYY-MM-DD')}
                                className={classes.formDate}
                            /> */}
                            {/* <DatePicker
                                timeFormat={false}
                                isValidDate={disableCustomDt}
                                className={classes.formDatePicker}
                                dateFormat="DD/MM/YYYY"
                                value={formData?.servicedate}
                                onChange={onChangeInput}
                            /> */}
                            {/* <DayPicker
                                initialMonth={new Date(2017, 3)}
                                disabledDays={[
                                    new Date(2017, 3, 12),
                                    new Date(2017, 3, 2),
                                    {
                                        after: new Date(2017, 3, 20),
                                        before: new Date(2017, 3, 25),
                                    },
                                ]}
                            /> */}
                            <DayPickerInput
                                onDayChange={day => onChangeDate(day)}
                                formatDate={formatDate}
                                format={FORMAT_DATE}
                                parseDate={parseDate}
                                placeholder={FORMAT_DATE}
                                dayPickerProps={{
                                    modifiers: {
                                      disabled: [
                                        ...disabledDateList,
                                        {
                                          before: new Date()
                                        },
                                        {
                                          after: maxDate
                                        }
                                      ]
                                    }
                                }}
                            />
                            {!formData?.servicedate && (
                                <div className={classes.formFieldError}>กรุณาเลือกวันที่เข้ารับบริการ</div>
                            )}
                        </div>     
                    </div>

                    <div className={classes.redeemBox}>
                        <div className={classes.redeemHeader} style={{ padding: '5px 6px' }}>
                            เวลาที่เข้ารับบริการ<span className={classes.req}>*</span>
                        </div>
                        <div className={classes.redeemDetail} style={{ fontSize: 14, padding: '6px 6px' }}>
                            <div>
                                {serviceTimeList.map((m, i) => (
                                        <div key={i} style={{ fontSize: 12, marginBottom: 10 }}>
                                            <input
                                                type="radio"
                                                id={m}
                                                name="servicetime"
                                                value={formData.servicetime}
                                                style={{ paddingTop: 10 }}
                                                checked={formData.servicetime === m}
                                                onChange={() => onChangeTime(m)}
                                            />
                                            <label for={m} style={{ marginLeft: 7, fontSize: 14 }}>{m}</label>
                                        </div>
                                    ))}
                               </div>
                            {formData.servicedate && !formData?.servicetime && (
                                <div className={classes.formFieldError}>กรุณาเลือกเวลาที่เข้ารับบริการ</div>
                            )}
                        </div>     
                    </div>

                    <div className={classes.blockBtn} style={{ paddingBottom: 20, paddingTop: 20 }}>
                        <button
                            className={disabledBtn ? clsx(classes.actionRedeem, classes.actionRedeemDisabled) : classes.actionRedeem}
                            onClick={onSubmit}
                            disabled={disabledBtn}
                            style={{ display: 'inline-block', marginLeft: 10 }}
                        >ตกลง</button>
                    </div>
                </div>
            </div>
        </Dialog>
    </>)
}

const CompleteDialog = ({
    open,
    onClose,
    data,
    onSaveComplete,
}) => {
    const classes = useStyles();

    const onClickComplete = () => {
        onSaveComplete(data);
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="xs"
            fullWidth
        >
            <div className={classes.dialog}>
                <Box align="right" className={classes.boxIcon}>
                    <IconButton onClick={onClose}>
                        <SvgIcon className={classes.closeIcon}>
                            <CloseIcon />
                        </SvgIcon>
                    </IconButton>
                </Box>
                <div className={classes.dialogTitle}>
                    
                </div>

                <div className={classes.dialogContent}>
                    <div className={classes.dialogTitle}>
                        ยืนยันเข้ารับการซ่อมเรียบร้อย
                    </div>

                    <div className={classes.redeemBox} style={{ marginTop: 20 }}>
                        <div className={classes.redeemHeader} style={{ padding: '0px 6px'}}>
                            หมายเลข
                        </div>
                        <div className={classes.redeemDetail} style={{ padding: '0px 6px'}}>
                            {data.refid}
                        </div>
                    </div>

                    <div className={classes.redeemBox} style={{ marginTop: 5 }}>
                        <div className={classes.redeemHeader} style={{ padding: '0px 6px'}}>
                            วันที่รับเข้ารับบริการ
                        </div>
                        <div className={classes.redeemDetail} style={{ padding: '0px 6px'}}>
                            {humanExpireTime(data.servicedate)}
                        </div>
                    </div>

                    <div className={classes.redeemBox} style={{ marginTop: 5 }}>
                        <div className={classes.redeemHeader} style={{ padding: '0px 6px'}}>
                            เวลาเข้ารับบริการ
                        </div>
                        <div className={classes.redeemDetail} style={{ padding: '0px 6px'}}>
                            {data.servicetime}
                        </div>
                    </div>

                    <div className={classes.blockBtn} style={{ marginTop: 20 }}>
                        <button
                            className={classes.actionRedeem}
                            onClick={onClickComplete}
                            style={{ display: 'inline-block', marginLeft: 10 }}
                        >ตกลง</button>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

const SearchCriteria = ({
    searchResult,
    criteria,
}) => {
    const classes = useStyles();
    const [text, setText] = useState();

    const onClickSearch = () => {
        searchResult({
            ...criteria,
            searchtext: text,
            page: '1',
        });
    }

    useEffect(() => {
        setText(criteria.searchtext);
    }, [criteria])

    return (
        <div style={{ display: 'flex', flexGrow: 1, alignItems: 'center' }}>
            <input className={classes.formSearch} 
                type="text"
                value={text}
                onChange={(e) => setText(e.target.value)}
                placeholder='ค้นหาด้วย ชื่อ-นามสกุล, เบอร์โทร หรือ เลขตัวถัง'
            />
            <IconButton onClick={onClickSearch}>
                <SvgIcon className={classes.searchIcon}>
                    <SearchIcon />
                </SvgIcon>
            </IconButton>
        </div>
    )
}

const FillterByCriteria = ({
    searchResult,
    criteria,
}) => {
    const classes = useStyles();
    const [formData, setFormData] = useState({});
    const [isOpen, setOpen] = useState(false);

    const [modelList, setModelList] = useState([]);

    useEffect(() => {
        if (isOpen) {
            setFormData({
                ...criteria
            })
        }
    }, [isOpen])

    useEffect(() => {
        getModelList();
    }, [])

    const getModelList = async () => {
        const response = await axios.post(API.GET_MODEL_LIST, {});
        setModelList(response.data.list || []);
    }

    const onClickSearch = () => {
        searchResult({
            ...criteria,
            ...formData,
            page: '1',
        });
        setOpen(false);
    }

    const onChangeInput = (e) => {
        let { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        })
    }

    const onClickOpenDialog = () => {
        setOpen(true);
    }

    const onCloseDialog = () => {
        setOpen(false);
    }

    return (<>
        <div className={classes.boxOrder}
            onClick={onClickOpenDialog}
        >
            <SvgIcon className={classes.filterIcon}>
                <FilterIcon />
            </SvgIcon>
            Fillter by
        </div>
        <Dialog
            open={isOpen}
            onClose={onCloseDialog}
            maxWidth="xs"
            fullWidth
        >
            <div className={classes.dialog}>
                <Box align="right" className={classes.boxIcon}>
                    <IconButton onClick={onCloseDialog}>
                        <SvgIcon className={classes.closeIcon}>
                            <CloseIcon />
                        </SvgIcon>
                    </IconButton>
                </Box>

                <div className={classes.dialogContent}>
                    <div className={classes.dialogTitle} style={{ marginBottom: 20 }}>
                        Fillter By
                    </div>

                    <div className={classes.redeemBox}>
                        <div className={classes.redeemHeader}>
                            วันที่เริ่มต้น
                        </div>
                        <div className={classes.redeemDetail}>
                            <input type="date"
                                name="searchservicedatebegin"
                                value={formData?.searchservicedatebegin}
                                onChange={onChangeInput}
                                className={classes.formSearchSelect}
                                max={formData?.searchservicedateend}
                            />
                        </div>
                    </div>

                    <div className={classes.redeemBox}>
                        <div className={classes.redeemHeader}>
                            วันที่สิ้นสุด
                        </div>
                        <div className={classes.redeemDetail}>
                            <input type="date"
                                name="searchservicedateend"
                                value={formData?.searchservicedateend}
                                onChange={onChangeInput}
                                className={classes.formSearchSelect}
                                min={formData?.searchservicedatebegin}
                            />
                        </div>
                    </div>

                    <div className={classes.redeemBox}>
                        <div className={classes.redeemHeader}>
                            รุ่นรถ
                        </div>
                        <div className={classes.redeemDetail}>
                            <div>
                                <select
                                    // className={classes.formSearchSelect}
                                    className='minimal'
                                    style={{ fontSize: 12 }}
                                    value={formData?.searchmodel}
                                    onChange={onChangeInput}
                                    name="searchmodel"
                                >
                                    <option value="">ทั้งหมด</option>
                                    {modelList.map(m => (
                                        <option value={m} key={m}>{m}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className={classes.redeemBox}>
                        <div className={classes.redeemHeader}>
                            สถานะ
                        </div>
                        <div className={classes.redeemDetail}>
                            <div>
                                <select
                                    // className={classes.formSearchSelect}
                                    className='minimal'
                                    style={{ fontSize: 12 }}
                                    value={formData?.searchservicestatus}
                                    onChange={onChangeInput}
                                    name="searchservicestatus"
                                >
                                    <option value="">ทั้งหมด</option>
                                    <option value='waiting_booking'>Waiting Booking</option>
                                    <option value='booking'>Booking</option>
                                    <option value='done'>Done</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className={classes.blockBtn} style={{ marginTop: 20 }}>
                        <button
                            className={classes.actionRedeem}
                            onClick={onClickSearch}
                        >ตกลง</button>
                    </div>
                </div>
            </div>
        </Dialog>
    </>)
}

const DateCriteria = ({
    searchResult,
    criteria,
}) => {
    const classes = useStyles();
    const [text, setText] = useState();

    const [isOpen, setOpen] = useState(false);
    const ref = useRef(null);

    const onClickSearch = () => {
        searchResult({
            ...criteria,
            searchtext: text,
            page: '1',
        });
    }


    return (<>
        <div
            className={classes.boxDate}
            onClick={() => setOpen(true)}
            ref={ref}
        >
            Date
            <SvgIcon className={classes.dateIcon}>
                <ChevronDownIcon />
            </SvgIcon>
        </div>
        <Menu
            onClose={() => setOpen(false)}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            anchorEl={ref.current}
            open={isOpen}
            getContentAnchorEl={null}
            keepMounted
            PaperProps={{ className: classes.popover }}
        >
            <MenuItem className={classes.menuItem2}>
                
            </MenuItem>
        </Menu>
    </>)
}

const initCreate = {
    customerid: '',
    customername: '',
    address: '',
    tumbon: '',
    district: '',
    province: '',
    postal: '',
    mobile: '',
    milage: '',
    vehicleregisternumber: '',
    serviceid: '',
    servicedate: '',
    servicetime: '',
    access: '',
    appversion: '',
    osversion: '',
    device: '',
    deviceid: '',
    serviceidother: '',

    framenumber: '',
    enginenumber: '',
    model: '',
}

const CreateDialog = ({
    open,
    onClose,
    onSaveCreate,
}) => {
    const classes = useStyles();
    const [formData, setFormData] = useState({});
    const [formBlur, setFormBlur] = useState({});
    const [disabledBtn, setDisabled] = useState(true);
    const [disabledBtn2, setDisabled2] = useState(true);
    const sizeframenumber = 17;

    const [serviceAllList, setServiceAllList] = useState([]);
    const [serviceList, setServiceList] = useState([]);
    const [otherServiceList, setOtherServiceList] = useState([]);

    const [serviceId, setServiceId] = useState();
    const [isBkk, setIsBkk] = useState(true);

    const [open2, setOpen2] = useState(false);

    useEffect(() => {
        if (open) {
            setServiceTimeList([]);
            setFormData({
                ...initCreate,
            });
            setFormBlur({})
        }
    }, [open])

    const onChangeInput = (e) => {
        let { name, value } = e.target;
        if (name === 'serviceidother') {
            setFormData({
                ...formData,
                [name]: value,
                servicedate: '',
                servicetime: '',
            })
            setFormBlur({
                ...formBlur,
                [name]: true
            })
        } else {
            setFormData({
                ...formData,
                [name]: value
            })
            setFormBlur({
                ...formBlur,
                [name]: true
            })
        }

        if (name === 'serviceidother' && formData.serviceid === 'other') {
            let findId = serviceAllList.find(f => f.facade === value);
            if (findId) {
                setServiceId(findId.serviceid);
                setIsBkk(findId.type === 'BKK');
            }
        }
    }

    const onBlur = (e) => {
        setFormBlur({
            ...formBlur,
            [e.target.name]: true
        })
    }

    const getService = async (dealercode) => {
        const response = await axios.post(API.GET_SERVICE_LIST, {
            dealercode: dealercode
        });

        let list = response.data.dealer || [];
        setServiceList(list);

        if (list.length) {
            let type = list[0]?.type;
            setIsBkk(type === 'BKK');
        }
    }

    useEffect(() => {
        getServiceAll(serviceList);
    }, [serviceList])

    const getServiceAll = async (serviceList) => {
        const response = await axios.post(API.GET_SERVICE_LIST, {});

        let list = response.data.dealer || [];
        setServiceAllList(list);

        let _otherServiceList = [];

        list.map(m => {
            let findService = serviceList.find(f => f.serviceid === m.serviceid);
            if (!findService) {
                _otherServiceList.push(m.facade);
            }
        })
        setOtherServiceList(_otherServiceList);
    }

    const onChangeService = (value) => {
        setFormData({
            ...formData,
            serviceid: value,
            serviceidother: '',
            servicedate: '',
            servicetime: '',
        })

        setServiceId(value);

        let findId = serviceAllList.find(f => f.serviceid === value);
        if (findId) {
            setIsBkk(findId.type === 'BKK');
        }
    }

    useEffect(() => {
        if (formData.framenumber && formData.framenumber.length === sizeframenumber) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }

        if (
            !formData.customername || !formData.mobile
            || !formData.address || !formData.tumbon || !formData.district
            || !formData.province || !formData.postal 
            || formData?.mobile.length !== 10
            // || !formData.email
            || (formData.email && (!(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData?.email))))
            || !formData.milage || !formData.vehicleregisternumber 
            || !formData.serviceid || (formData.serviceid === 'other' && !formData.serviceidother)
            || !formData.servicetime || !formData.servicedate
        ) {
            setDisabled2(true);
        } else {
            setDisabled2(false);
        }

        if (formData.province === 'กรุงเทพมหานคร') {
            setIsBkk(true);
        } else {
            setIsBkk(false);
        }

        if (formData.servicedate && serviceId) {
            getServiceTime(serviceId, formData.servicedate);
        }

        if (serviceId) {
            getServiceDate(serviceId);
        }
    }, [formData])

    const [serviceTimeList, setServiceTimeList] = useState([]);

    const getServiceTime = async (serviceid, servicetime) => {
        const response = await axios.post(API.GET_SERVICE_TIME, {
            serviceid: serviceid,
            servicedate: servicetime
        });

        let list = response.data.servicetime || [];
        setServiceTimeList(list.map(m => m.time));
        if (!list.length) {
            alert('จำนวนการจองเต็ม / วันหยุดศูนย์บริการ');
        }
    }

    const onChangeTime = (value) => {
        setFormData({
            ...formData,
            servicetime: value
        })
    }

    const onClickCheckFrameNumber = () => {
        if (formData.framenumber.length !== sizeframenumber) {
            return
        }
        (async () => {
            const response = await axios.post(API.CUSTOMER_CHECK_SERVICE, {
                framenumber: formData.framenumber,
            });

            let data = response?.data;
            if (data?.result === 'error') {
                if (data?.detail === 'not_found') {
                    alert('ไม่พบหมายเลขตัวถัง');
                } else {
                    alert('เลขถังนี้ไม่ต้องทำการอัพเกรด');
                }
            } else {
                if (data?.info?.upgraded || data?.detail === 'upgrade_done') {
                    alert('หมายเลขถังนี้ทำการอัพเกรดแล้ว');
                } else if (data?.detail === "found") {
                    onGetCustomer(formData.framenumber);
                } else if (data?.detail === 'existing_register') {
                    alert('หมายเลขถังนี้มีการจองอยู่แล้ว');
                } else {
                    alert('เลขถังนี้ไม่ต้องทำการอัพเกรด');
                }
            }
        })(); 
    }

    const onClose2 = () => {
        setOpen2(false);
    }

    const onGetCustomer = async (framenumber) => {
        onClose();
        let formData = {
            framenumber: framenumber,
            enginenumber: '',
        }
        const response = await axios.post(API.CUSTOMER_CHECK_SERVICE, formData);

        let data = response?.data;
        setFormData({
            ...initCreate,
            customerid: data?.info?.customerid,
            customername: data?.info?.customername,
            address: data?.info?.address,
            tumbon: data?.info?.tumbon,
            district: data?.info?.district,
            province: data?.info?.province,
            postal: data?.info?.postal,
            mobile: data?.info?.mobile,
            milage: '',
            vehicleregisternumber: '',
            serviceid: '',
            servicedate: '',
            servicetime: '',
            access: '',
            appversion: '',
            osversion: '',
            device: '',
            deviceid: '',
        
            framenumber: data?.info?.framenumber,
            enginenumber:data?.info?.enginenumber,
            model: data?.info?.model,
            email: data?.info?.email
        })

        getService(data?.info?.dealercode);
        setOpen2(true);
    }

    const onSubmit = () => {
        if (
            !formData.customername || !formData.mobile
            || !formData.address || !formData.tumbon || !formData.district
            || !formData.province || !formData.postal 
            || formData?.mobile.length !== 10
            // || !formData.email
            || (formData.email && (!(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData?.email))))
            || !formData.milage || !formData.vehicleregisternumber 
            || !formData.serviceid || (formData.serviceid === 'other' && !formData.serviceidother)
            || !formData.servicetime || !formData.servicedate
        ) {
            return
        }
        setOpen2(false);

        let servicedate = moment(formData.servicedate).format('yyyy-MM-DD');
        onSaveCreate({ 
            ...formData,
            serviceid: serviceId,
            servicedate: servicedate,
            access: getAccess(),
            appversion: getAppVersion(),
            osversion: '',
            device: getDevice(),
            deviceid: '',
        });
    }

    const onChangeDate = (day) => {
        setFormData({
            ...formData,
            servicedate: day,
        })
    }

    const [maxDate, setMaxDate] = useState();
    const [disabledDateList, setDisabledDateList] = useState([]);

    const getServiceDate = async (serviceid) => {
        const response = await axios.post(API.GET_SERVICE_DATE, {
            serviceid: serviceid,
        });
        
        let list = response.data.servicedate || [];
        let maxDate = '';
        if (list.length) {
            maxDate = list[list.length - 1];
        }

        let dateMax = null;
        if (maxDate) {
            let splits = maxDate.split('-');
            dateMax = new Date(parseInt(splits[0]), parseInt(splits[1]) - 1, parseInt(splits[2]));
        }
        if (dateMax) {
            setMaxDate(dateMax);
        }

        const response2 = await axios.post(API.GET_SERVICE_DATE_DIS, {
            serviceid: serviceid,
        });
        let listDisabled = [];
        let listdate = response2.data.unavailabledate || [];
        listdate.map(m => {
            let splits = m.split('-');
            let date = new Date(parseInt(splits[0]), parseInt(splits[1]) - 1, parseInt(splits[2]));
            listDisabled.push(date);
        })
        setDisabledDateList(listDisabled);
    }

    return (<>
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="xs"
            fullWidth
        >
            <div className={classes.dialog}>
                <Box align="right" className={classes.boxIcon}>
                    <IconButton onClick={onClose}>
                        <SvgIcon className={classes.closeIcon}>
                            <CloseIcon />
                        </SvgIcon>
                    </IconButton>
                </Box>

                <div className={classes.dialogContent}>
                    <div className={classes.dialogTitle} style={{ marginBottom: 20 }}>
                        เพิ่มการจอง
                    </div>

                    <div className={classes.redeemBox}>
                        <div className={classes.redeemHeader}>
                            หมายเลขตัวถัง
                        </div>
                        <div className={classes.redeemDetail}>
                            <div>
                                <input className={classes.formFieldInput} 
                                    type="text"
                                    name="framenumber"
                                    value={formData?.framenumber}
                                    onChange={onChangeInput}
                                    onBlur={onBlur}
                                    maxLength={sizeframenumber}
                                />
                            </div>
                            {!formData?.framenumber && formBlur?.framenumber && (
                                <div className={classes.formFieldError}>กรุณากรอกเลขตัวถัง</div>
                            )}
                            {formData?.framenumber && (
                                <>
                                    {!(/^[a-zA-Z0-9]+$/.test(formData?.framenumber)) ? (
                                        <div className={classes.formFieldError}>เลขตัวถังไม่ถูกต้อง</div>
                                    ) 
                                    : formData?.framenumber.length < sizeframenumber ? (
                                        <div className={classes.formFieldError}>ความยาว 17 หลัก</div>
                                    ) 
                                    : null}
                                </>
                            )}
                        </div>
                    </div>

                    <div className={classes.blockBtn} style={{ marginTop: 20 }}>
                        <button
                            className={disabledBtn ? clsx(classes.actionRedeem, classes.actionRedeemDisabled) : classes.actionRedeem}
                            onClick={onClickCheckFrameNumber}
                            disabled={disabledBtn}
                            style={{ display: 'inline-block', marginLeft: 10 }}
                        >ถัดไป</button>
                    </div>
                </div>
            </div>
        </Dialog>

        <Dialog
            open={open2}
            onClose={onClose2}
            maxWidth="xs"
            fullWidth
        >
            <div className={classes.dialog}>
                <Box align="right" className={classes.boxIcon}>
                    <IconButton onClick={onClose2}>
                        <SvgIcon className={classes.closeIcon}>
                            <CloseIcon />
                        </SvgIcon>
                    </IconButton>
                </Box>

                <div className={classes.dialogContent}>
                    <div className={classes.dialogTitle} style={{ marginBottom: 20 }}>
                        เพิ่มการจอง
                    </div>

                    <div className={classes.redeemBox}>
                        <div className={classes.redeemHeader}>
                            ชื่อ-นามสกุล<span className={classes.req}>*</span>
                        </div>
                        <div className={classes.redeemDetail}>
                            <div>
                                <input className={classes.formFieldInput} 
                                    type="text"
                                    name="customername"
                                    value={formData?.customername}
                                    onChange={onChangeInput}
                                    onBlur={onBlur}
                                />
                            </div>
                            {!formData?.customername && (
                                <div className={classes.formFieldError}>กรุณาชื่อ-นามสกุล</div>
                            )}
                        </div>
                    </div>

                    <div className={classes.redeemBox}>
                        <div className={classes.redeemHeader}>
                            เบอร์มือถือ<span className={classes.req}>*</span>
                        </div>
                        <div className={classes.redeemDetail}>
                            <div>
                                <input className={classes.formFieldInput} 
                                    type="text"
                                    name="mobile"
                                    value={formData?.mobile}
                                    onChange={onChangeInput}
                                    onBlur={onBlur}
                                    maxLength={10}
                                    onInput={e => {
                                        e.target.value = !Number.isInteger(
                                          parseInt(e.target.value)
                                        )
                                          ? ''
                                          : ~[
                                              '0',
                                              '1',
                                              '2',
                                              '3',
                                              '4',
                                              '5',
                                              '6',
                                              '7',
                                              '8',
                                              '9'
                                            ].indexOf(
                                              e.target.value[e.target.value.length - 1]
                                            )
                                          ? e.target.value
                                          : e.target.value.slice(
                                              0,
                                              e.target.value.length - 1
                                            );
                                    }}
                                />
                            </div>
                            {!formData?.mobile && (
                                <div className={classes.formFieldError}>กรุณาเบอร์มือถือ</div>
                            )}
                             {formData?.mobile && formData?.mobile.length !== 10 && (
                                <div className={classes.formFieldError}>กรอกเบอร์มือถือ 10 หลัก</div>
                            )}
                        </div>
                    </div>

                    <div className={classes.redeemBox}>
                        <div className={classes.redeemHeader}>
                            อีเมล
                        </div>
                        <div className={classes.redeemDetail}>
                            <div>
                                <input className={classes.formFieldInput} 
                                    type="text"
                                    name="email"
                                    value={formData?.email}
                                    onChange={onChangeInput}
                                    onBlur={onBlur}
                                />
                            </div>
                            {/* {!formData?.email && (
                                <div className={classes.formFieldError}>กรุณาอีเมล</div>
                            )} */}
                            {formData?.email && !(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData?.email)) && (
                                <div className={classes.formFieldError}>อีเมลไม่ถูกต้อง</div>
                            )}
                        </div>
                    </div>

                    <div className={classes.redeemBox}>
                        <div className={classes.redeemHeader}>
                            ที่อยู่
                            <span className={classes.req}>*</span>
                        </div>
                        <div className={classes.redeemDetail}>
                            <div>
                                <input className={classes.formFieldInput} 
                                    type="text"
                                    name="address"
                                    value={formData?.address}
                                    onChange={onChangeInput}
                                    onBlur={onBlur}
                                />
                            </div>
                            {!formData?.address && (
                                <div className={classes.formFieldError}>กรุณาที่อยู่</div>
                            )}
                        </div>
                    </div>

                    <div className={classes.redeemBox}>
                        <div className={classes.redeemHeader}>
                            ตำบล / แขวง
                            <span className={classes.req}>*</span>
                        </div>
                        <div className={classes.redeemDetail}>
                            <div>
                                <input className={classes.formFieldInput} 
                                    type="text"
                                    name="tumbon"
                                    value={formData?.tumbon}
                                    onChange={onChangeInput}
                                    onBlur={onBlur}
                                />
                            </div>
                            {!formData?.tumbon && (
                                <div className={classes.formFieldError}>กรุณาตำบล / แขวง</div>
                            )}
                        </div>
                    </div>

                    <div className={classes.redeemBox}>
                        <div className={classes.redeemHeader}>
                            อำเภอ / เขต
                            <span className={classes.req}>*</span>
                        </div>
                        <div className={classes.redeemDetail}>
                            <div>
                                <input className={classes.formFieldInput} 
                                    type="text"
                                    name="district"
                                    value={formData?.district}
                                    onChange={onChangeInput}
                                    onBlur={onBlur}
                                />
                            </div>
                            {!formData?.district && (
                                <div className={classes.formFieldError}>กรุณาอำเภอ / เขต</div>
                            )}
                        </div>
                    </div>

                    <div className={classes.redeemBox}>
                        <div className={classes.redeemHeader}>
                            จังหวัด
                            <span className={classes.req}>*</span>
                        </div>
                        <div className={classes.redeemDetail}>
                            <div>
                                <input className={classes.formFieldInput} 
                                    type="text"
                                    name="province"
                                    value={formData?.province}
                                    onChange={onChangeInput}
                                    onBlur={onBlur}
                                />
                            </div>
                            {!formData?.province && (
                                <div className={classes.formFieldError}>กรุณาจังหวัด</div>
                            )}
                        </div>
                    </div>

                    <div className={classes.redeemBox}>
                        <div className={classes.redeemHeader}>
                            รหัสไปรษณีย์
                            <span className={classes.req}>*</span>
                        </div>
                        <div className={classes.redeemDetail}>
                            <div>
                                <input className={classes.formFieldInput} 
                                    type="text"
                                    name="postal"
                                    value={formData?.postal}
                                    onChange={onChangeInput}
                                    onBlur={onBlur}
                                />
                            </div>
                            {!formData?.postal && (
                                <div className={classes.formFieldError}>กรุณารหัสไปรษณีย์</div>
                            )}
                        </div>
                    </div>

                    <div className={classes.redeemBox}>
                        <div className={classes.redeemHeader}>
                            หมายเลขตัวถัง
                            <span className={classes.req}>*</span>
                        </div>
                        <div className={classes.redeemDetail}>
                            <div>
                                <input className={classes.formFieldInput2} 
                                    type="text"
                                    name="framenumber"
                                    value={formData?.framenumber}
                                    disabled
                                />
                            </div>
                        </div>
                    </div>

                    <div className={classes.redeemBox}>
                        <div className={classes.redeemHeader}>
                            เวสป้ารุ่น
                            <span className={classes.req}>*</span>
                        </div>
                        <div className={classes.redeemDetail}>
                            <div>
                                <input className={classes.formFieldInput2} 
                                    type="text"
                                    name="model"
                                    value={formData?.model}
                                    disabled
                                />
                            </div>
                        </div>
                    </div>

                    <div className={classes.redeemBox}>
                        <div className={classes.redeemHeader}>
                            ระยะทาง
                            <span className={classes.req}>*</span>
                        </div>
                        <div className={classes.redeemDetail2}>
                            <div>
                                <input className={classes.formFieldInput} 
                                    type="number"
                                    name="milage"
                                    value={formData?.milage}
                                    onChange={onChangeInput}
                                    onBlur={onBlur}
                                    onInput={e => {
                                        e.target.value = !Number.isInteger(
                                          parseInt(e.target.value)
                                        )
                                          ? ''
                                          : ~[
                                              '0',
                                              '1',
                                              '2',
                                              '3',
                                              '4',
                                              '5',
                                              '6',
                                              '7',
                                              '8',
                                              '9'
                                            ].indexOf(
                                              e.target.value[e.target.value.length - 1]
                                            )
                                          ? e.target.value
                                          : e.target.value.slice(
                                              0,
                                              e.target.value.length - 1
                                            );
                                    }}
                                />
                            </div>
                            {!formData?.milage && (
                                <div className={classes.formFieldError}>กรุณากรอกเป็นตัวเลข</div>
                            )}
                        </div>
                        <div className={classes.redeemDetail3}>
                            กิโลเมตร
                        </div>
                    </div>

                    <div className={classes.redeemBox}>
                        <div className={classes.redeemHeader}>
                            ทะเบียนรถ
                            <span className={classes.req}>*</span>
                        </div>
                        <div className={classes.redeemDetail}>
                            <div>
                                <input className={classes.formFieldInput} 
                                    type="text"
                                    name="vehicleregisternumber"
                                    value={formData?.vehicleregisternumber}
                                    onChange={onChangeInput}
                                    onBlur={onBlur}
                                />
                            </div>
                            {!formData?.vehicleregisternumber && (
                                <div className={classes.formFieldError}>กรุณาข้อมูลให้ครบ</div>
                            )}
                        </div>
                    </div>

                    <div className={classes.redeemBox}>
                        <div className={classes.redeemHeader}>
                            ศูนย์บริการ
                            <span className={classes.req}>*</span>
                        </div>
                        <div className={classes.redeemRadio}>
                            {serviceList.map((m, i) => (
                                <div key={i} style={{ fontSize: 12, marginBottom: 10 }}>
                                    <input
                                        type="radio"
                                        id={m.serviceid}
                                        name="serviceid"
                                        value={formData.serviceid}
                                        style={{ paddingTop: 10 }}
                                        checked={m.serviceid === formData.serviceid}
                                        onChange={() => onChangeService(m.serviceid)}
                                    />
                                    <label for={m.serviceid} style={{ marginLeft: 7, fontSize: 12 }}>{m.facade}</label>
                                </div>
                            ))}
                            {otherServiceList.length > 0 && (
                                <div style={{ fontSize: 12, marginBottom: 10 }}>
                                    <input
                                        type="radio"
                                        id={"other"}
                                        name="serviceid"
                                        value={"other"}
                                        style={{ paddingTop: 10 }}
                                        checked={'other'=== formData.serviceid}
                                        onChange={() => onChangeService('other')}
                                    />
                                    <label for={"other"} style={{ marginLeft: 7, fontSize: 12 }}>อื่นๆ</label>

                                    <div style={{ marginTop: 7 }}>
                                        {formData.serviceid === 'other' ? (
                                            <select
                                                className='minimal'
                                                // className={classes.formFieldSelect}
                                                value={formData?.serviceidother}
                                                onChange={onChangeInput}
                                                onBlur={onBlur}
                                                name="serviceidother"
                                            >
                                                <option hidden>เลือกศูนย์บริการ</option>
                                                {otherServiceList.map(m => (
                                                    <option value={m} key={m}>{m}</option>
                                                ))}
                                            </select>
                                        ) : (
                                            // <input className={classes.formFieldInput} 
                                            //     style={{ width: 200 }}
                                            //     type="text"
                                            //     name="serviceidother"
                                            //     placeholder="เลือกศูนย์บริการ"
                                            //     value={formData?.serviceidother}
                                            //     onChange={onChangeInput}
                                            //     onBlur={onBlur}
                                            //     list="branchlist"
                                            //     disabled={formData.serviceid !== 'other'}
                                            // />
                                            <select
                                                className='minimal'
                                                disabled
                                            >
                                                <option hidden>เลือกศูนย์บริการ</option>
                                            </select>
                                        )} 
                                    </div>
                                </div>
                            )}
                            {(!formData?.serviceid || (formData.serviceid === 'other' && !formData.serviceidother)) && (
                                <div className={classes.formFieldError}>กรุณาเลือกศูนย์บริการ</div>
                            )}
                        </div>
                        {/* <div className={classes.redeemDetail}>
                            <div>
                                <input className={classes.formFieldInput} 
                                    type="text"
                                    name="serviceidother"
                                    placeholder="เลือกศูนย์บริการ"
                                    value={formData?.serviceidother}
                                    onChange={onChangeInput}
                                    onBlur={onBlur}
                                    list="branchlist"
                                />
                                <datalist id="branchlist">
                                    {otherServiceList.map(m => (
                                        <option value={m} key={m}>{m}</option>
                                    ))}
                                </datalist>
                            </div>
                            {(!formData?.serviceidother) && (
                                <div className={classes.formFieldError}>กรุณาเลือกศูนย์บริการ</div>
                            )}
                        </div> */}
                    </div>

                    <div className={classes.redeemBox}>
                        <div className={classes.redeemHeader}>
                            วันที่เข้ารับบริการ<span className={classes.req}>*</span>
                        </div>
                        <div className={classes.redeemDetail}>
                            {/* <input type="date"
                                name="servicedate"
                                value={formData?.servicedate}
                                onChange={onChangeInput}
                                min={moment().format('YYYY-MM-DD')}
                                className={classes.formDate}
                            /> */}
                            <DayPickerInput
                                onDayChange={day => onChangeDate(day)}
                                formatDate={formatDate}
                                format={FORMAT_DATE}
                                parseDate={parseDate}
                                placeholder={FORMAT_DATE}
                                dayPickerProps={{
                                    modifiers: {
                                      disabled: [
                                        ...disabledDateList,
                                        {
                                          before: new Date()
                                        },
                                        {
                                          after: maxDate
                                        }
                                      ]
                                    }
                                }}
                                value={formData.servicedate}
                            />
                            {!formData?.servicedate && (
                                <div className={classes.formFieldError}>กรุณาเลือกวันที่เข้ารับบริการ</div>
                            )}
                        </div>     
                    </div>

                    <div className={classes.redeemBox}>
                        <div className={classes.redeemHeader} style={{ padding: '5px 6px' }}>
                            เวลาที่เข้ารับบริการ<span className={classes.req}>*</span>
                        </div>
                        <div className={classes.redeemDetail} style={{ fontSize: 14, padding: '6px 6px' }}>
                            <div>
                                {serviceTimeList.map((m, i) => (
                                        <div key={i} style={{ fontSize: 12, marginBottom: 10 }}>
                                            <input
                                                type="radio"
                                                id={m}
                                                name="servicetime"
                                                value={formData.servicetime}
                                                style={{ paddingTop: 10 }}
                                                checked={formData.servicetime === m}
                                                onChange={() => onChangeTime(m)}
                                            />
                                            <label for={m} style={{ marginLeft: 7, fontSize: 14 }}>{m}</label>
                                        </div>
                                    ))}
                               </div>
                            {formData.servicedate && !formData?.servicetime && (
                                <div className={classes.formFieldError}>กรุณาเลือกเวลาที่เข้ารับบริการ</div>
                            )}
                        </div>     
                    </div>

                    <div className={classes.blockBtn} style={{ marginTop: 20, marginBottom: 20 }}>
                        <button
                            className={disabledBtn2 ? clsx(classes.actionRedeem, classes.actionRedeemDisabled) : classes.actionRedeem}
                            onClick={onSubmit}
                            disabled={disabledBtn2}
                            style={{ display: 'inline-block', marginLeft: 10 }}
                        >ตกลง</button>
                    </div>
                </div>
            </div>
        </Dialog>
    </>)
}

const EditDialog = ({
    open,
    onClose,
    onSaveEdit,
    data,
}) => {
    const classes = useStyles();
    const [formData, setFormData] = useState({});
    const [formBlur, setFormBlur] = useState({});
    const [disabledBtn, setDisabled] = useState(true);
 
    useEffect(() => {
        if (open) {
            setFormData({
                ...data,
            });
            setFormBlur({})
        }
    }, [open])

    const onChangeInput = (e) => {
        let { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        })
        setFormBlur({
            ...formBlur,
            [name]: true
        })
    }

    const onBlur = (e) => {
        setFormBlur({
            ...formBlur,
            [e.target.name]: true
        })
    }

    useEffect(() => {
        if (
            !formData.customername || !formData.mobile
            || !formData.address || !formData.tumbon || !formData.district
            || !formData.province || !formData.postal 
            || formData?.mobile.length !== 10
            // || !formData.email
            || (formData.email && (!(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData?.email))))
            || !formData.milage || !formData.vehicleregisternumber 
        ) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [formData])

    const onSubmit = () => {
        if (
            !formData.customername || !formData.mobile
            || !formData.address || !formData.tumbon || !formData.district
            || !formData.province || !formData.postal 
            || formData?.mobile.length !== 10
            // || !formData.email
            || (formData.email && (!(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData?.email))))
            || !formData.milage || !formData.vehicleregisternumber 
        ) {
            return
        }

        setOpenConfirm(true);
    }

    const [openConfirm, setOpenConfirm] = useState(false);

    const onSave = () => {
        setOpenConfirm(false);
        onSaveEdit({ 
            ...formData,
            access: getAccess(),
            appversion: getAppVersion(),
            osversion: '',
            device: getDevice(),
            deviceid: '',
        }, true);
    }

    const onCloseConfirm = () => {
        setOpenConfirm(false);
        onClose();
    }

    return (<>

        <ConfirmDialog
            open={openConfirm}
            onClose={onCloseConfirm}
            onOk={onSave}
        />

        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="xs"
            fullWidth
        >
            <div className={classes.dialog}>
                <Box align="right" className={classes.boxIcon}>
                    <IconButton onClick={onClose}>
                        <SvgIcon className={classes.closeIcon}>
                            <CloseIcon />
                        </SvgIcon>
                    </IconButton>
                </Box>

                <div className={classes.dialogContent}>
                    <div className={classes.dialogTitle} style={{ marginBottom: 20 }}>
                        แก้ไขข้อมูลผู้ใช้บริการ
                    </div>

                    <div className={classes.redeemBox}>
                        <div className={classes.redeemHeader}>
                            ชื่อ-นามสกุล<span className={classes.req}>*</span>
                        </div>
                        <div className={classes.redeemDetail}>
                            <div>
                                <input className={classes.formFieldInput} 
                                    type="text"
                                    name="customername"
                                    value={formData?.customername}
                                    onChange={onChangeInput}
                                    onBlur={onBlur}
                                />
                            </div>
                            {!formData?.customername && (
                                <div className={classes.formFieldError}>กรุณาชื่อ-นามสกุล</div>
                            )}
                        </div>
                    </div>

                    <div className={classes.redeemBox}>
                        <div className={classes.redeemHeader}>
                            เบอร์มือถือ<span className={classes.req}>*</span>
                        </div>
                        <div className={classes.redeemDetail}>
                            <div>
                                <input className={classes.formFieldInput} 
                                    type="text"
                                    name="mobile"
                                    value={formData?.mobile}
                                    onChange={onChangeInput}
                                    onBlur={onBlur}
                                    maxLength={10}
                                    onInput={e => {
                                        e.target.value = !Number.isInteger(
                                          parseInt(e.target.value)
                                        )
                                          ? ''
                                          : ~[
                                              '0',
                                              '1',
                                              '2',
                                              '3',
                                              '4',
                                              '5',
                                              '6',
                                              '7',
                                              '8',
                                              '9'
                                            ].indexOf(
                                              e.target.value[e.target.value.length - 1]
                                            )
                                          ? e.target.value
                                          : e.target.value.slice(
                                              0,
                                              e.target.value.length - 1
                                            );
                                    }}
                                />
                            </div>
                            {!formData?.mobile && (
                                <div className={classes.formFieldError}>กรุณาเบอร์มือถือ</div>
                            )}
                             {formData?.mobile && formData?.mobile.length !== 10 && (
                                <div className={classes.formFieldError}>กรอกเบอร์มือถือ 10 หลัก</div>
                            )}
                        </div>
                    </div>

                    <div className={classes.redeemBox}>
                        <div className={classes.redeemHeader}>
                            อีเมล
                        </div>
                        <div className={classes.redeemDetail}>
                            <div>
                                <input className={classes.formFieldInput} 
                                    type="text"
                                    name="email"
                                    value={formData?.email}
                                    onChange={onChangeInput}
                                    onBlur={onBlur}
                                />
                            </div>
                            {/* {!formData?.email && (
                                <div className={classes.formFieldError}>กรุณาอีเมล</div>
                            )} */}
                            {formData?.email && !(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData?.email)) && (
                                <div className={classes.formFieldError}>อีเมลไม่ถูกต้อง</div>
                            )}
                        </div>
                    </div>

                    <div className={classes.redeemBox}>
                        <div className={classes.redeemHeader}>
                            ที่อยู่
                            <span className={classes.req}>*</span>
                        </div>
                        <div className={classes.redeemDetail}>
                            <div>
                                <input className={classes.formFieldInput} 
                                    type="text"
                                    name="address"
                                    value={formData?.address}
                                    onChange={onChangeInput}
                                    onBlur={onBlur}
                                />
                            </div>
                            {!formData?.address && (
                                <div className={classes.formFieldError}>กรุณาที่อยู่</div>
                            )}
                        </div>
                    </div>

                    <div className={classes.redeemBox}>
                        <div className={classes.redeemHeader}>
                            ตำบล / แขวง
                            <span className={classes.req}>*</span>
                        </div>
                        <div className={classes.redeemDetail}>
                            <div>
                                <input className={classes.formFieldInput} 
                                    type="text"
                                    name="tumbon"
                                    value={formData?.tumbon}
                                    onChange={onChangeInput}
                                    onBlur={onBlur}
                                />
                            </div>
                            {!formData?.tumbon && (
                                <div className={classes.formFieldError}>กรุณาตำบล / แขวง</div>
                            )}
                        </div>
                    </div>

                    <div className={classes.redeemBox}>
                        <div className={classes.redeemHeader}>
                            อำเภอ / เขต
                            <span className={classes.req}>*</span>
                        </div>
                        <div className={classes.redeemDetail}>
                            <div>
                                <input className={classes.formFieldInput} 
                                    type="text"
                                    name="district"
                                    value={formData?.district}
                                    onChange={onChangeInput}
                                    onBlur={onBlur}
                                />
                            </div>
                            {!formData?.district && (
                                <div className={classes.formFieldError}>กรุณาอำเภอ / เขต</div>
                            )}
                        </div>
                    </div>

                    <div className={classes.redeemBox}>
                        <div className={classes.redeemHeader}>
                            จังหวัด
                            <span className={classes.req}>*</span>
                        </div>
                        <div className={classes.redeemDetail}>
                            <div>
                                <input className={classes.formFieldInput} 
                                    type="text"
                                    name="province"
                                    value={formData?.province}
                                    onChange={onChangeInput}
                                    onBlur={onBlur}
                                />
                            </div>
                            {!formData?.province && (
                                <div className={classes.formFieldError}>กรุณาจังหวัด</div>
                            )}
                        </div>
                    </div>

                    <div className={classes.redeemBox}>
                        <div className={classes.redeemHeader}>
                            รหัสไปรษณีย์
                            <span className={classes.req}>*</span>
                        </div>
                        <div className={classes.redeemDetail}>
                            <div>
                                <input className={classes.formFieldInput} 
                                    type="text"
                                    name="postal"
                                    value={formData?.postal}
                                    onChange={onChangeInput}
                                    onBlur={onBlur}
                                />
                            </div>
                            {!formData?.postal && (
                                <div className={classes.formFieldError}>กรุณารหัสไปรษณีย์</div>
                            )}
                        </div>
                    </div>

                    <div className={classes.redeemBox}>
                        <div className={classes.redeemHeader}>
                            หมายเลขตัวถัง
                            <span className={classes.req}>*</span>
                        </div>
                        <div className={classes.redeemDetail}>
                            <div>
                                <input className={classes.formFieldInput2} 
                                    type="text"
                                    name="framenumber"
                                    value={formData?.framenumber}
                                    disabled
                                />
                            </div>
                        </div>
                    </div>

                    <div className={classes.redeemBox}>
                        <div className={classes.redeemHeader}>
                            เวสป้ารุ่น
                            <span className={classes.req}>*</span>
                        </div>
                        <div className={classes.redeemDetail}>
                            <div>
                                <input className={classes.formFieldInput2} 
                                    type="text"
                                    name="model"
                                    value={formData?.model}
                                    disabled
                                />
                            </div>
                        </div>
                    </div>

                    <div className={classes.redeemBox}>
                        <div className={classes.redeemHeader}>
                            ระยะทาง
                            <span className={classes.req}>*</span>
                        </div>
                        <div className={classes.redeemDetail2}>
                            <div>
                                <input className={classes.formFieldInput} 
                                    type="number"
                                    name="milage"
                                    value={formData?.milage}
                                    onChange={onChangeInput}
                                    onBlur={onBlur}
                                    onInput={e => {
                                        e.target.value = !Number.isInteger(
                                          parseInt(e.target.value)
                                        )
                                          ? ''
                                          : ~[
                                              '0',
                                              '1',
                                              '2',
                                              '3',
                                              '4',
                                              '5',
                                              '6',
                                              '7',
                                              '8',
                                              '9'
                                            ].indexOf(
                                              e.target.value[e.target.value.length - 1]
                                            )
                                          ? e.target.value
                                          : e.target.value.slice(
                                              0,
                                              e.target.value.length - 1
                                            );
                                    }}
                                />
                            </div>
                            {!formData?.milage && (
                                <div className={classes.formFieldError}>กรุณากรอกเป็นตัวเลข</div>
                            )}
                        </div>
                        <div className={classes.redeemDetail3}>
                            กิโลเมตร
                        </div>
                    </div>

                    <div className={classes.redeemBox}>
                        <div className={classes.redeemHeader}>
                            ทะเบียนรถ
                            <span className={classes.req}>*</span>
                        </div>
                        <div className={classes.redeemDetail}>
                            <div>
                                <input className={classes.formFieldInput} 
                                    type="text"
                                    name="vehicleregisternumber"
                                    value={formData?.vehicleregisternumber}
                                    onChange={onChangeInput}
                                    onBlur={onBlur}
                                />
                            </div>
                            {!formData?.vehicleregisternumber && (
                                <div className={classes.formFieldError}>กรุณาข้อมูลให้ครบ</div>
                            )}
                        </div>
                    </div>

                    <div className={classes.blockBtn} style={{ marginTop: 20 }}>
                        <button
                            className={disabledBtn ? clsx(classes.actionRedeem, classes.actionRedeemDisabled) : classes.actionRedeem}
                            onClick={onSubmit}
                            disabled={disabledBtn}
                            style={{ display: 'inline-block', marginLeft: 10 }}
                        >ตกลง</button>
                    </div>
                </div>
            </div>
        </Dialog>
    </>)
}