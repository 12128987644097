import { makeStyles } from '@material-ui/core/styles';
import BackIcon from '../images/heading__anchor.png';
import RadioItem from '../images/radio.png';
import RadioCheckItem from '../images/radio--checked.png';
import Arrow from '../images/arrow.png';

const muiStyles = makeStyles((theme) => ({
    container: {
        visibility: 'visible',
        minHeight: '100vh'
    },
    content: {
        paddingBottom: 300,
        [theme.breakpoints.down('sm')]: {
            paddingBottom: 230,
        },
        [theme.breakpoints.down('xs')]: {
            paddingBottom: 120,
        }
    },
    avatarFrame: {
        background: '#3a4440',
        borderRadius: '50%',
        overflow: 'hidden',
        marginTop: 50,
        height: 270,
        width: 270,
        '@media (max-width: 768px)': {
            marginTop: 30,
            height: 210,
            width: 210,
        },
        '@media (max-width: 600px)': {
            marginTop: 20,
            height: 140,
            width: 140,
        },
        '@media (max-width: 500px)': {
            marginTop: 10,
            height: 110,
            width: 110,
        },
    },
    language: {
        position: 'absolute',
        right: '1rem',
        top: 0,
        fontSize: 50,
        padding: '20px 70px',
        [theme.breakpoints.down('sm')]: {
            fontSize: 40,
            padding: '20px 50px',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 24,
            padding: '5px 15px',
        }
    },
    languageAnchor: {
        cursor: 'pointer',
        display: 'inline-block',
        lineHeight: 0.8,
        padding: '10px',
        [theme.breakpoints.down('sm')]: {
            padding: '7px',
        },
        [theme.breakpoints.down('xs')]: {
            padding: '5px',
        }
    },
    languageAnchorActive: {
        color: 'white',
        cursor: 'pointer',
        display: 'inline-block',
        lineHeight: 0.8,
        padding: '10px',
        [theme.breakpoints.down('sm')]: {
            padding: '7px',
        },
        [theme.breakpoints.down('xs')]: {
            padding: '5px',
        }
    },
    wrapper: {
        margin: 'auto',
        width: '100%',
    },
    heading: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        fontSize: 100,
        padding: 30,
        [theme.breakpoints.down('sm')]: {
            fontSize: 75,
            padding: 25,
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 36,
            padding: 20,
        }
    },
    headingTab: {
        display: 'flex',
        justifyContent: 'space-evenly',
        width: '100%',
    },
    headingAnchor: {
        color: '#000',
        cursor: 'pointer',
        textDecoration: 'none',
        opacity: '0.3',
        display: 'block',
        flex: 1,
        textAlign: 'center',
        fontSize: 55,
        padding: '30px 0px',
        [theme.breakpoints.down('sm')]: {
            fontSize: 40,
            padding: '20px 0px',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 20,
            padding: '10px 0px',
        }
    },
    headingAnchorActive: {
        pointerEvents: 'none',
        opacity: 1
    },
    headingPath: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        fontSize: 80,
        padding: 45,
        [theme.breakpoints.down('sm')]: {
            fontSize: 60,
            padding: 35,
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 27,
            padding: 20,
        }
    },
    headingText: {
        // whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '70vw',
        textAlign: 'center'
    },
    headingBack: {
        background: `url(${BackIcon}) no-repeat 0 0/cover`,
        cursor: 'pointer',
        position: 'absolute',
        height: 60,
        width: 40,
        left: 55,
        [theme.breakpoints.down('sm')]: {
            height: 45,
            width: 30,
            left: 35,
        },
        [theme.breakpoints.down('xs')]: {
            height: 22,
            width: 15,
            left: 20,
        }
    },
    coupon: {
        margin: 'auto'
    },
    coupons: {
        padding: '0px 40px',
        marginBottom: 80,
        [theme.breakpoints.down('sm')]: {
            padding: '0px 30px',
            marginBottom: 70,
        },
        [theme.breakpoints.down('xs')]: {
            padding: '0px 15px',
            marginBottom: 30,
        }
    },
    couponItem: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 50,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 30,
        },
        [theme.breakpoints.down('xs')]: {
            marginBottom: 20,
        }
    },
    couponFooter: {
        backgroundColor: '#000',
        color: 'white',
        width: '100%',
        margin: 'auto',
        textAlign: 'center',
        fontSize: 50,
        [theme.breakpoints.down('sm')]: {
            fontSize: 40,
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 18,
        }
    },
    couponRunout: {
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        color: 'white',
        width: '100%',
        textAlign: 'center',
        position: 'absolute',
        fontSize: 70,
        padding: '50px 0px',
        [theme.breakpoints.down('sm')]: {
            fontSize: 50,
            padding: '40px 0px',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 20,
            padding: '20px 0px',
        }
    },
    couponImageWrap: {
        position: 'relative',
    },
    couponNumberWrap: {
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        display: 'flex',
        position: 'absolute',
        width: '100%',
        bottom: 0,
        height: '100%',
    },
    couponNumber: {
        backgroundColor: '#000',
        color: 'white',
        borderRadius: 40,
        fontSize: 40,
        padding: '10px 20px',
        margin: '10px',
        [theme.breakpoints.down('sm')]: {
            fontSize: 30,
            padding: '6px 17px',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 16,
            padding: '3px 10px',
            margin: '5px',
        }
    },
    couponCodeWrap: {
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
        display: 'flex',
        position: 'absolute',
        width: '100%',
        bottom: 0,
        height: '100%',
        pointerEvents: 'none'
    },
    couponCode: {
        border: '1px solid',
        backgroundColor: 'white',
        color: '#000',
        borderRadius: 40,
        fontSize: 40,
        padding: '10px 20px',
        margin: '10px',
        [theme.breakpoints.down('sm')]: {
            fontSize: 30,
            padding: '6px 17px',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 16,
            padding: '3px 10px',
            margin: '5px',
        }
    },
    couponDetail: {
        position: 'absolute',
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        color: 'white',
        textAlign: 'center',
        borderRadius: 60,
        textDecoration: 'none',
        fontSize: 70,
        padding: '40px 70px',
        [theme.breakpoints.down('sm')]: {
            fontSize: 60,
            padding: '30px 60px',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 30,
            padding: '20px 35px',
        }
    },
    empty: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '50vh'
    },
    emptyMsg: {
        opacity: 0.3,
        fontSize: 90,
        [theme.breakpoints.down('sm')]: {
            fontSize: 70,
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 30,
        }
    },
    remark: {
        margin: '30px 0px',
        [theme.breakpoints.down('xs')]: {
            margin: '20px 0px',
        }
    },
    remarkHeading: {
        fontSize: 70,
        [theme.breakpoints.down('sm')]: {
            fontSize: 50,
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 26,
        }
    },
    remarkGroup: {
        listStyle: 'disc',
        margin: 0,
        padding: '0 0 0 100px',
        [theme.breakpoints.down('sm')]: {
            padding: '0 0 0 70px'
        },
        [theme.breakpoints.down('xs')]: {
            padding: '0 0 0 32px'
        }
    },
    remarkList: {
        fontSize: 60,
        margin: '25px 0px',
        [theme.breakpoints.down('sm')]: {
            fontSize: 40,
            margin: '15px 0px',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 22,
            margin: '5px 0px',
        }
    },
    form: {
        margin: 'auto',
        padding: '10px 70px',
        [theme.breakpoints.down('sm')]: {
            padding: '10px 40px',
            marginTop: '-20px'
        },
        [theme.breakpoints.down('xs')]: {
            padding: '0px 25px',
            marginTop: '0px'
        },
    },
    form2: {
        margin: 'auto',
        padding: '10px 70px',
        marginTop: '30px',
        paddingBottom: 350,
        [theme.breakpoints.down('sm')]: {
            padding: '10px 40px',
            marginTop: '20px',
            paddingBottom: 300,
        },
        [theme.breakpoints.down('xs')]: {
            padding: '0px 25px',
            marginTop: '20px',
            paddingBottom: 150,
        },
    },
    form3: {
        margin: 'auto',
        marginTop: 40,
        paddingBottom: 10,
        [theme.breakpoints.down('sm')]: {
            marginTop: 40,
            paddingBottom: 10,
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: 20,
            paddingBottom: 5,
        },
    },
    formItem: {
        margin: '0 0 40px',
        [theme.breakpoints.down('sm')]: {
            margin: '0 0 30px',
        },
        [theme.breakpoints.down('xs')]: {
            margin: '0 0 15px',
        }
    },
    formItem2: {
        width: '45%',
        display: 'inline-block',
    },
    formItem3: {
        width: '50%',
        display: 'inline-block',
    },
    formLabel: {
        fontSize: 55,
        padding: '0px 12px',
        [theme.breakpoints.down('sm')]: {
            padding: '0px 10px',
            fontSize: 45,
        },
        [theme.breakpoints.down('xs')]: {
            padding: '0px 7px',
            fontSize: 22,
        }
    },
    formFieldInput: {
        backgroundColor: '#e5e5e5',
        border: 'none',
        borderRadius: '50px',
        boxSizing: 'border-box',
        outline: 'none',
        fontFamily: 'myFont',
        width: '100%',

        padding: '0 45px',
        height: 100,
        fontSize: 55,
        [theme.breakpoints.down('sm')]: {
            fontSize: 45,
            height: 70,
            padding: '0 30px',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 22,
            height: 38,
            padding: '0 20px',
        },

        '&:focus': {
            boxShadow: 'inset 0 0 0px 2px #69cdb3',
        }
    },
    formFieldTextArea: {
        backgroundColor: '#e5e5e5',
        border: 'none',
        borderRadius: '20px',
        boxSizing: 'border-box',
        outline: 'none',
        fontFamily: 'myFont',
        width: '100%',

        padding: '20px 45px',
        // height: 100,
        fontSize: 55,
        [theme.breakpoints.down('sm')]: {
            fontSize: 45,
            // height: 70,
            padding: '20px 30px',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 22,
            // height: 38,
            padding: '10px 20px',
        },

        '&:focus': {
            boxShadow: 'inset 0 0 0px 2px #69cdb3',
        }
    },
    formFieldError: {
        color: 'red',
        fontSize: 47,
        textIndent: '15px',
        [theme.breakpoints.down('sm')]: {
            fontSize: 40,
            textIndent: '10px'
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 18,
            textIndent: '7px'
        },
    },
    formFieldWrap: {
        display: 'inline-block',
        position: 'relative',
        margin: '0 60px 0 60px',
        [theme.breakpoints.down('sm')]: {
            margin: '0 50px 0 50px',
        },
        [theme.breakpoints.down('xs')]: {
            margin: '0 10px 0 20px',
        },
    },
    formFieldWrap2: {
        display: 'inline-block',
        position: 'relative',
    },
    formFieldRadio: {
        height: 0,
        opacity: 0,
        position: 'absolute',
        width: 0,
    },
    formRadioBgCheck: {
        background: `url(${RadioCheckItem}) no-repeat 50% 50%/100%`,
    },
    formRadioBg: {
        background: `url(${RadioItem}) no-repeat 50% 50%/100%`,
    },
    formRadio: {
        display: 'inline-block',
        position: 'absolute',
        left: 0,
        top: 9,
        width: 50,
        height: 50,
        [theme.breakpoints.down('sm')]: {
            top: 12,
            width: 32,
            height: 32,
        },
        [theme.breakpoints.down('xs')]: {
            top: 5,
            width: 16,
            height: 16,
        },
    },
    formFieldLink: {
        display: 'inline-block',
        textDecoration: 'underline',
        fontSize: 55,
        marginRight: 110,
        [theme.breakpoints.down('sm')]: {
            fontSize: 45,
            marginRight: 75,
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 22,
            marginRight: 30,
        },
    },
    formFieldTitle: {
        display: 'inline-block',
        fontSize: 55,
        textIndent: '15px',
        marginLeft: 55,
        [theme.breakpoints.down('sm')]: {
            fontSize: 45,
            textIndent: '10px',
            marginLeft: 35,
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 22,
            textIndent: '7px',
            marginLeft: 15,
        },
    },
    formFieldWrapAccept: {
        background: `url(${Arrow}) no-repeat 0 0/100% 100%`,
        position: 'absolute',
        marginTop: 13,
        marginLeft: 10,
        width: 45,
        height: 45,
        [theme.breakpoints.down('sm')]: {
            marginTop: 12,
            marginLeft: 7,
            width: 32,
            height: 32,
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: 6,
            marginLeft: 3,
            width: 15,
            height: 15,
        },
    },
    formSelectEmpty: {
        color: 'rgba(0, 0, 0, 0.5)',
    },
    formBirthDayDate: {
        width: '29%',
        paddingRight: '2%',
        display: 'inline-block',
    },
    formBirthDayMonth: {
        width: '37%',
        paddingRight: '2%',
        display: 'inline-block',
    },
    formBirthDayYear: {
        width: '30%',
        display: 'inline-block',
    },
    overlay: {
        background: 'rgba(0, 0, 0, 0.8)',
        height: '100%',
        left: 0,
        position: 'fixed',
        top: 0,
        width: '100%'
    },
    loader: {
        bottom: 0,
        left: 0,
        margin: 'auto',
        position: 'absolute',
        right: 0,
        top: 0,
        zIndex: 1,
        height: 160,
        width: 160,
        [theme.breakpoints.down('sm')]: {
            height: 140,
            width: 140,
        },
        [theme.breakpoints.down('xs')]: {
            height: 120,
            width: 120,
        }
    },
    loader2: {
        bottom: 0,
        left: 0,
        margin: 'auto',
        position: 'absolute',
        right: 0,
        top: 0,
        zIndex: 1,
        padding: 10,

        height: 450,
        width: 450,
        [theme.breakpoints.down('sm')]: {
            height: 400,
            width: 400,
            // padding: 40,
        },
        [theme.breakpoints.down('xs')]: {
            height: 300,
            width: 300,
            
        }
    },
    loaderImage: {
        display: 'block',
        width: '100%',
    },
    action: {
        background: 'white',
        position: 'fixed',
        width: '100%',
        bottom: 0,

        padding: '50px 0px',
        [theme.breakpoints.down('sm')]: {
            padding: '40px 0px',
        },
        [theme.breakpoints.down('xs')]: {
            padding: '20px 0px',
        },
    },
    actionRedeem: {
        background: '#000',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        textDecoration: 'none',

        fontSize: 70,
        height: 120,
        width: 380,
        borderRadius: '100px',
        margin: 'auto auto 2px',
        [theme.breakpoints.down('sm')]: {
            fontSize: 60,
            height: 100,
            width: 340,
            borderRadius: '50px',
            margin: 'auto auto 2px',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 30,
            height: 50,
            width: 170,
            borderRadius: '50px',
            margin: 'auto auto 2px',
        },
    },
    action2: {
        background: 'white',
        position: 'fixed',
        width: '100%',
        justifyContent: 'center',
        display: 'flex',
        bottom: 0,

        padding: '50px 0px',
        [theme.breakpoints.down('sm')]: {
            padding: '40px 0px',
        },
        [theme.breakpoints.down('xs')]: {
            padding: '20px 0px',
        },
    },
    actionButton: {
        background: '#000',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        textDecoration: 'none',

        fontSize: 70,
        height: 120,
        width: 380,
        borderRadius: '100px',
        margin: 'auto auto 2px',
        [theme.breakpoints.down('sm')]: {
            fontSize: 60,
            height: 100,
            width: 340,
            borderRadius: '50px',
            margin: 'auto auto 2px',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 26,
            height: 50,
            width: 160,
            borderRadius: '50px',
            margin: 'auto auto 2px',
        },
    },
    actionSubmit: {
        background: 'white',
        color: '#000',
    },
    actionRedeemDisabled: {
        opacity: 0.1,
    },
    redeem: {
        paddingTop: '20px',
        [theme.breakpoints.down('sm')]: {
            paddingTop: '20px',
        },
        [theme.breakpoints.down('xs')]: {
            paddingTop: '10px',
        }
    },
    redeemBox: {
        padding: '10px 0px',
        [theme.breakpoints.down('sm')]: {
            padding: '10px 0px',
        },
        [theme.breakpoints.down('xs')]: {
            padding: '5px 0px',
        },
        display: 'flex',
        flexGrow: 1,
    },
    redeemHeader: {
        fontSize: 55,
        padding: '0px 12px',
        display: 'inline-block',
        width: '35%',
        fontWeight: 600,
        textAlign: 'right',
        [theme.breakpoints.down('sm')]: {
            padding: '0px 10px',
            fontSize: 45,
        },
        [theme.breakpoints.down('xs')]: {
            padding: '0px 7px',
            fontSize: 22,
        }
    },
    redeemDetail: {
        fontSize: 55,
        padding: '0px 12px',
        display: 'inline-block',
        width: '65%',
        [theme.breakpoints.down('sm')]: {
            padding: '0px 10px',
            fontSize: 45,
        },
        [theme.breakpoints.down('xs')]: {
            padding: '0px 7px',
            fontSize: 22,
        }
    },
    redeemDetail2: {
        fontSize: 55,
        padding: '0px 12px',
        [theme.breakpoints.down('sm')]: {
            padding: '0px 10px',
            fontSize: 45,
        },
        [theme.breakpoints.down('xs')]: {
            padding: '0px 7px',
            fontSize: 22,
        }
    },
    editor: {
        background: 'white',
        width: '100%',
        padding: '50px 0px',
        [theme.breakpoints.down('sm')]: {
            padding: '40px 0px',
        },
        [theme.breakpoints.down('xs')]: {
            padding: '20px 0px',
        },
    },
    editorRedeem: {
        background: '#000',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        textDecoration: 'none',

        fontSize: 60,
        height: 100,
        width: 340,
        borderRadius: '100px',
        margin: 'auto auto 2px',
        [theme.breakpoints.down('sm')]: {
            fontSize: 50,
            height: 90,
            width: 300,
            borderRadius: '50px',
            margin: 'auto auto 2px',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 26,
            height: 45,
            width: 150,
            borderRadius: '50px',
            margin: 'auto auto 2px',
        },
    },
    confirmRemark: {
        border: '1px solid #ff0000',
        borderRadius: 5,
        background: '#fff2f2',
        boxSizing: 'border-box',

        fontSize: 55,
        marginTop: 50,
        padding: '0px 30px',
        [theme.breakpoints.down('sm')]: {
            fontSize: 45,
            marginTop: 40,
            padding: '0px 20px',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 20,
            marginTop: 20,
            padding: '0px 10px',
        }
    },
    confirmParagraph: {

    },
    confirmHilight: {
        color: '#ff0000',
        textDecoration: 'underline',
        fontWeight: 600
    },
    confirmUnderLine: {
        textDecoration: 'underline',
        fontWeight: 600
    },
}));

export default muiStyles