import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import '../App.css';
// import '../css/common.css';
import '../css/awesomplete.css';
import axios from 'axios';
import { API } from '../hooks/api';
import { makeStyles } from '@material-ui/core';
import Loading from '../components/Loading';
import { useParams } from 'react-router-dom';
import { availableMonths, getAccess, getAppVersion, getDevice } from './helpper';
import moment from 'moment';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';

import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parse';

function parseDate(str, format, locale) {
//   const parsed = dateFnsParse(str, format, new Date(), { locale });
//   console.log(format, str, locale);
//   console.log(parsed);
//   if (DateUtils.isDate(parsed)) {
//     return parsed;
//   }
//   return undefined;
    let splits = str.split('/');
    let date = new Date(parseInt(splits[2]), parseInt(splits[1]) - 1, parseInt(splits[0]));
    return date;
}

function formatDate(date, format, locale) {
  return dateFnsFormat(date, format, { locale });
}

const FORMAT_DATE = 'DD/MM/YYYY';

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: '#FFFFFF',
        minHeight: '100vh',
    },
    container2: {
        backgroundColor: '#FFFFFF',
        minHeight: '100vh',
        display: 'flex',
        // alignItems: 'center',
        justifyContent: 'center',
        marginTop: 30,
    },
    block: {
        display: 'block',
    },
    title: {
        color: '#6DCDB1',
        fontWeight: 700,
        fontSize: 22,
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 15,
        fontFamily: "myFontBold",
    },
    title4: {
        fontWeight: 700,
        fontSize: 18,
        display: 'flex',
        justifyContent: 'flex-start',
        paddingLeft: 20,
        marginBottom: 15,
        marginTop: 40,
        fontFamily: "myFontBold"
    },
    detail: {
        color: '#000',
        fontSize: 14,
        display: 'flex',
        justifyContent: 'center',
    },

    blockBtn: {
        marginTop: 30,
        // display: 'flex',
        justifyContent: 'center',
        marginBottom: 10,
        textAlign: 'center',
    },
    blockBtn2: {
        marginTop: 30,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: 20,
        marginBottom: 10,
    },
    actionRedeem: {
        background: '#000',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        textDecoration: 'none',

        fontSize: 20,
        height: 50,
        width: 150,
        borderRadius: '50px',
        margin: 'auto auto 2px',
        fontWeight: 600,
        cursor: 'pointer',
        [theme.breakpoints.down('xs')]: {
            fontSize: 18,
        }
    },
    actionRedeemDisabled: {
        // opacity: 0.5,
        background: 'rgba(0,0,0,0.5)'
    },

    redeemBox: {
        padding: '8px 0px',
        display: 'flex',
        flexGrow: 1,
    },
    redeemHeader: {
        padding: '8px 6px',
        width: '30%',
        textAlign: 'right',
        fontSize: 12,
    },
    redeemHeader2: {
        padding: '0px 6px',
        width: '100%',
        fontSize: 12,
    },
    redeemDetail: {
        fontSize: 12,
        padding: '0px 6px',
        width: '70%',
    },
    redeemDetail2: {
        fontSize: 12,
        padding: '0px 6px',
        width: '50%',
    },
    redeemDetail3: {
        padding: '8px 6px',
        width: '20%',
        fontSize: 12,
    },
    redeemRadio: {
        padding: '8px 6px',
        width: '80%',
        fontSize: 12,
        marginLeft: '10%'
    },
    req: {
        color: 'red',
        marginLeft: 2
    },

    formFieldInput: {
        backgroundColor: '#FAFAFA',
        borderRadius: '2px',
        border: '1px solid #CCCCCC',
        boxSizing: 'border-box',
        outline: 'none',
        fontFamily: 'myFont',
        width: '100%',
        fontSize: 12,
        height: 30,
        padding: '0 10px',
        '&:focus': {
            boxShadow: 'inset 0 0 0px 2px #69cdb3',
        },
    },
    formFieldInput2: {
        backgroundColor: '#FAFAFA',
        borderRadius: '2px',
        border: '1px solid #CCCCCC',
        boxSizing: 'border-box',
        outline: 'none',
        fontFamily: 'myFont',
        width: '100%',
        fontSize: 12,
        height: 30,
        padding: '0 10px',
        '&:focus': {
            boxShadow: 'inset 0 0 0px 2px #69cdb3',
        },
        '&:disabled': {
            background: '#C8C8C8',
        }
    },
    formFieldSelect: {
        backgroundColor: '#FAFAFA',
        borderRadius: '2px',
        border: '1px solid #CCCCCC',
        boxSizing: 'border-box',
        outline: 'none',
        fontFamily: 'myFont',
        width: 200,
        fontSize: 12,
        height: 30,
        padding: '0 10px',
        '&:focus': {
            boxShadow: 'inset 0 0 0px 2px #69cdb3',
        },
    },
    formFieldError: {
        color: 'red',
        textIndent: '5px',
        fontSize: 11,
        marginTop: 3,
    },

    containerComplete: {
        backgroundColor: '#6DCDB1',
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    blockComplete: {
        display: 'block',
    },
    title3: {
        color: 'white',
        fontWeight: 700,
        fontSize: 30,
        display: 'flex',
        justifyContent: 'center',
        textShadow: '0px 2px 2px #02855F',
        marginBottom: 15,
        fontFamily: "myFontBold",
        [theme.breakpoints.down('xs')]: {
            fontSize: 24,
        }
    },
    title32: {
        color: '#444444',
        fontWeight: 700,
        fontSize: 20,
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 15,
        fontFamily: "myFontBold"
    },
    title33: {
        color: 'white',
        fontWeight: 700,
        fontSize: 17,
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 7,
        fontFamily: "myFontBold",
        [theme.breakpoints.down('xs')]: {
            fontSize: 16,
        }
    },
    title34: {
        color: '#8E2F2C',
        fontSize: 20,
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 15,
        textDecoration: 'underline',
        [theme.breakpoints.down('xs')]: {
            fontSize: 18,
        }
    },
    redeemBoxComplete: {
        padding: '8px 0px',
        display: 'flex',
        flexGrow: 1,
    },
    redeemHeaderComplete: {
        padding: '0px 6px',
        width: '50%',
        fontSize: 16,
        color: '#444444',
        [theme.breakpoints.down('xs')]: {
            fontSize: 15,
        }
    },
    redeemDetailComplete: {
        fontSize: 16,
        padding: '0px 6px',
        width: '50%',
        color: '#444444',
        [theme.breakpoints.down('xs')]: {
            fontSize: 15,
        }
    },
    blockReceive: {
        marginTop: 30,
        backgroundColor: '#FFF',
        borderRadius: 10,
        padding: '20px 10px',
    },

    formDate: {
        backgroundColor: '#FAFAFA',
        borderRadius: '2px',
        border: '1px solid #CCCCCC',
        boxSizing: 'border-box',
        outline: 'none',
        fontFamily: 'myFont',
        // width: '100%',
        fontSize: 13,
        height: 36,
        padding: '0 10px',
        '&:focus': {
            boxShadow: 'inset 0 0 0px 2px #69cdb3',
        },
    },
    actionRedeemBack: {
        background: '#000',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        textDecoration: 'none',

        fontSize: 20,
        height: 50,
        width: 190,
        borderRadius: '50px',
        margin: 'auto auto 2px',
        cursor: 'pointer',
        fontWeight: 600,
        [theme.breakpoints.down('xs')]: {
            fontSize: 18,
        }
    },
}));

const init = {
    customerid: '',
    customername: '',
    address: '',
    tumbon: '',
    district: '',
    province: '',
    postal: '',
    mobile: '',
    milage: '',
    vehicleregisternumber: '',
    serviceid: '',
    servicedate: '',
    servicetime: '',
    access: '',
    appversion: '',
    osversion: '',
    device: '',
    deviceid: '',
    serviceidother: '',

    framenumber: '',
    enginenumber: '',
    model: '',
}

const RegisterBooking = () => {
    const classes2 = useStyles();
    const { id } = useParams();
    const [loading, setLoading] = useState(false);

    const [isComplete, setIsComplete] = useState(false);
    const [isNext, setIsNext] = useState(false);

    const [formData, setFormData] = useState({...init});
    const [formBlur, setFormBlur] = useState({});
    const [disabledBtn, setDisabled] = useState(true);
    const [disabledBtn2, setDisabled2] = useState(true);
    const [disabledEdit, setDisabledEdit] = useState(false);

    const [dealerCode, setDealarCode] = useState();

    const [dataReceive, setDataReceive] = useState({
        customername: '',
        refid: "",
        facade: "",
        servicedate: "",
        servicetime: "",
    });

    useEffect(() => {
        getCustomer();
    }, []);

    const getCustomer = async () => {
        let apiPath = API.CUSTOMER_CHECK_SERVICE;
        let formData = {
            framenumber: id,
            enginenumber: '',
        }

        const response = await axios.post(apiPath, formData);
        // console.log("customer", response);

        let data = response?.data;
        setFormData({
            ...init,
            customerid: data?.info?.customerid,
            customername: data?.info?.customername,
            address: data?.info?.address,
            tumbon: data?.info?.tumbon,
            district: data?.info?.district,
            province: data?.info?.province,
            postal: data?.info?.postal,
            mobile: data?.info?.mobile,
            milage: '',
            vehicleregisternumber: '',
            serviceid: '',
            servicedate: '',
            servicetime: '',
            access: '',
            appversion: '',
            osversion: '',
            device: '',
            deviceid: '',
        
            framenumber: data?.info?.framenumber,
            enginenumber:data?.info?.enginenumber,
            model: data?.info?.model,
            email: data?.info?.email
        })

        getService(data?.info?.dealercode);
    }

    const [serviceAllList, setServiceAllList] = useState([]);
    const [serviceList, setServiceList] = useState([]);
    const [otherServiceList, setOtherServiceList] = useState([]);

    const [serviceId, setServiceId] = useState();

    const getService = async (dealercode) => {
        const response = await axios.post(API.GET_SERVICE_LIST, {
            dealercode: dealercode
        });

        let list = response.data.dealer || [];
        setServiceList(list);

        if (list.length) {
            let type = list[0]?.type;
            setIsBkk(type === 'BKK');
        }
    }

    useEffect(() => {
        getServiceAll(serviceList);
    }, [serviceList])

    const getServiceAll = async (serviceList) => {
        const response = await axios.post(API.GET_SERVICE_LIST, {});

        let list = response.data.dealer || [];
        setServiceAllList(list);

        let _otherServiceList = [];

        list.map(m => {
            let findService = serviceList.find(f => f.serviceid === m.serviceid);
            if (!findService) {
                _otherServiceList.push(m.facade);
            }
        })
        setOtherServiceList(_otherServiceList);
    }

    const onChangeInput = (e) => {
        let { name, value } = e.target;

        if (name === 'serviceidother') {
            setFormData({
                ...formData,
                [name]: value,
                servicedate: '',
                servicetime: '',
            })
            setFormBlur({
                ...formBlur,
                [name]: true
            })
            setServiceTimeList([]);
        } else {
            setFormData({
                ...formData,
                [name]: value
            })
            setFormBlur({
                ...formBlur,
                [name]: true
            })
        }

        if (name === 'serviceidother' && formData.serviceid === 'other') {
            let findId = serviceAllList.find(f => f.facade === value);
            if (findId) {
                setServiceId(findId.serviceid);
                setIsBkk(findId.type === 'BKK');
            }
        }
    }

    const onBlur = (e) => {
        setFormBlur({
            ...formBlur,
            [e.target.name]: true
        })
    }

    const onChangeService = (value) => {
        setFormData({
            ...formData,
            serviceid: value,
            serviceidother: '',
            servicedate: '',
            servicetime: '',
        })

        setServiceId(value);
        setServiceTimeList([]);

        let findId = serviceAllList.find(f => f.serviceid === value);
        if (findId) {
            setIsBkk(findId.type === 'BKK');
        }
    }

    const onChangeTime = (value) => {
        setFormData({
            ...formData,
            servicetime: value
        })
    }

    const [isBkk, setIsBkk] = useState(true);

    useEffect(() => {
        if (
            !formData.customername || !formData.mobile
            || !formData.address || !formData.tumbon || !formData.district
            || !formData.province || !formData.postal 
            || formData?.mobile.length !== 10
            // || !formData.email
            || (formData.email && (!(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData?.email))))
            || !formData.milage || !formData.vehicleregisternumber 
            || !formData.serviceid || (formData.serviceid === 'other' && !formData.serviceidother)
        ) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }

        if (!formData.servicedate || !formData.servicetime) {
            setDisabled2(true);
        } else {
            setDisabled2(false);
        }

        if (formData.servicedate && serviceId) {
            getServiceTime(serviceId, formData.servicedate);
        }

        if (serviceId) {
            getServiceDate(serviceId);
        }
    }, [formData])

    const onClickNext = () => {
        setIsNext(true);
    }

    const [serviceTimeList, setServiceTimeList] = useState([]);

    const getServiceTime = async (serviceid, servicetime) => {
        const response = await axios.post(API.GET_SERVICE_TIME, {
            serviceid: serviceid,
            servicedate: servicetime
        });

        let list = response.data.servicetime || [];
        setServiceTimeList(list.map(m => m.time));
        if (!list.length) {
            alert('จำนวนการจองเต็ม / วันหยุดศูนย์บริการ');
        }
    }

    const [dataComplete, setDataComplete] = useState({
        refid: ""
    })

    const [serviceDate, setServiceDate] = useState();

    const onSubmit = () => {
        if (
            !formData.customername || !formData.mobile
            || !formData.address || !formData.tumbon || !formData.district
            || !formData.province || !formData.postal 
            || formData?.mobile.length !== 10
                        // || !formData.email
                        || (formData.email && (!(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData?.email))))
            || !formData.milage || !formData.vehicleregisternumber 
            || !formData.serviceid || (formData.serviceid === 'other' && !formData.serviceidother)
        ) {
            return
        }
        setLoading(true);
        (async () => {
            let servicedate = moment(formData.servicedate).format('yyyy-MM-DD');
            
            const response = await axios.post(API.CUSTOMER_REGISTER, {
                ...formData,
                serviceid: serviceId,
                servicedate: servicedate,
                access: getAccess(),
                appversion: getAppVersion(),
                osversion: '',
                device: getDevice(),
                deviceid: '',
            });
            console.log("check", response);
            setLoading(false);
            let data = response?.data;

            if (data?.result === 'error') {
                if (data?.detail === 'cannot_booking') {
                    alert('ไม่สามารถจองเวลาได้');
                } else if (data?.detail === 'cannot_register') {
                    alert('ไม่สามารถลงทะเบียนได้');
                } else if (data?.detail === 'invalid_serviceid') {
                    alert('ศูนย์บริการไม่ถูกต้อง')
                } else if (data?.detail === 'jobdone') {
                    alert('ไม่สามารถแก้ไขได้ เนื่องจากบันทึกการเข้ารับบริการแล้ว');
                } else {
                    alert('ไม่สามารถลงทะเบียนได้')
                }
            } else {
                setIsComplete(true);
                setDataComplete({
                    refid: data?.refid
                })
                if (servicedate) {
                    var redeemdate = servicedate;
                    var date = parseInt(redeemdate.split('-')[2]);
                    var month = parseInt(redeemdate.split('-')[1]); 
                    var year = parseInt(redeemdate.split('-')[0]);
                    var dateFormat = `${date} ${availableMonths[month - 1]?.th || ''} ${(year||0)+543}`;
                    setServiceDate(dateFormat);
                }
            }
        })(); 
    }

    const onCloseWindow = () => {
        window.location = '/';
    }

    // useEffect(() => {
    //     console.log("appversion: ", getAppVersion());
    //     console.log("access: ", getAccess());
    //     console.log("device: ", getDevice());
    // }, [])

    const onChangeDate = (day) => {
        setFormData({
            ...formData,
            servicedate: day,
        })
    }

    const [maxDate, setMaxDate] = useState();
    const [disabledDateList, setDisabledDateList] = useState([]);

    const getServiceDate = async (serviceid) => {
        const response = await axios.post(API.GET_SERVICE_DATE, {
            serviceid: serviceid,
        });
        
        let list = response.data.servicedate || [];
        let maxDate = '';
        if (list.length) {
            maxDate = list[list.length - 1];
        }

        let dateMax = null;
        if (maxDate) {
            let splits = maxDate.split('-');
            dateMax = new Date(parseInt(splits[0]), parseInt(splits[1]) - 1, parseInt(splits[2]));
        }
        if (dateMax) {
            setMaxDate(dateMax);
        }

        const response2 = await axios.post(API.GET_SERVICE_DATE_DIS, {
            serviceid: serviceid,
        });
        let listDisabled = [];
        let listdate = response2.data.unavailabledate || [];
        listdate.map(m => {
            let splits = m.split('-');
            let date = new Date(parseInt(splits[0]), parseInt(splits[1]) - 1, parseInt(splits[2]));
            listDisabled.push(date);
        })
        setDisabledDateList(listDisabled);
    }

    return (<>
        {loading && <Loading />}
    {
        (isComplete && isBkk) ? (
            <div className={classes2.containerComplete}>
                <div className={classes2.blockComplete}>
                    <div className={classes2.title}>ลงทะเบียนเพื่อรับการอัพเกรดฟรี</div>
                    <div className={classes2.title3}>ลงทะเบียนสำเร็จ</div>
                    <div className={classes2.title32}>ขอบคุณ คุณ{formData.customername}</div>
                    
                    <div style={{ marginTop: 30 }}>
                        <div className={classes2.redeemBoxComplete}>
                            <div className={classes2.redeemHeaderComplete}>
                                ลำดับการจอง
                            </div>
                            <div className={classes2.redeemDetailComplete}>
                                {dataComplete?.refid || ''}
                            </div>
                        </div>

                        <div className={classes2.redeemBoxComplete}>
                            <div className={classes2.redeemHeaderComplete}>
                                รุ่นรถ
                            </div>
                            <div className={classes2.redeemDetailComplete}>
                                {formData?.model || ''}
                            </div>
                        </div>
                    </div>

                    <div className={classes2.title33} style={{ marginTop: 30 }}>ทางเวสป้า จะติดต่อกลับ</div>
                    <div className={classes2.title33}>เพื่อนัดหมายวัน-เวลา</div>
                    <div className={classes2.title33}>และศูนย์บริการที่เข้าบริการ</div>
                    <div className={classes2.title34}>ภายใน 5 วันทำการ</div>
                    <div className={classes2.blockBtn} style={{ marginTop: 30 }}>
                        <a className={classes2.actionRedeemBack} onClick={onCloseWindow}>กลับไปหน้าหลัก</a>
                    </div>
                </div>
            </div>
        ) : (isComplete && !isBkk) ? (
            <div className={classes2.containerComplete}>
                <div className={classes2.blockComplete}>
                    <div className={classes2.title}>ลงทะเบียนเพื่อรับการอัพเกรดฟรี</div>
                    <div className={classes2.title3}>เราได้ทำการบันทึก</div>
                    <div className={classes2.title3}>ตารางรับบริการของคุณ</div>
                    <div className={classes2.title3}>เรียบร้อยแล้ว</div>
                    
                    <div className={classes2.blockReceive} style={{ marginTop: 30 }}>
                        <div className={classes2.redeemBoxComplete}>
                            <div className={classes2.redeemHeaderComplete} style={{ textAlign: 'right'}}>
                                ชื่อ
                            </div>
                            <div className={classes2.redeemDetailComplete}>
                                {formData.customername || ''}
                            </div>
                        </div>

                        <div className={classes2.redeemBoxComplete}>
                            <div className={classes2.redeemHeaderComplete} style={{ textAlign: 'right'}}>
                                ลำดับการจอง
                            </div>
                            <div className={classes2.redeemDetailComplete}>
                                {dataComplete?.refid || ''}
                            </div>
                        </div>

                        <div className={classes2.redeemBoxComplete}>
                            <div className={classes2.redeemHeaderComplete} style={{ textAlign: 'right'}}>
                                ศูนย์บริการ
                            </div>
                            <div className={classes2.redeemDetailComplete} style={{ color: '#0532D2' }}>
                                {serviceAllList.find(f => f.serviceid === serviceId)?.facade || ''}
                            </div>
                        </div>

                        <div className={classes2.redeemBoxComplete}>
                            <div className={classes2.redeemHeaderComplete} style={{ textAlign: 'right'}}>
                                วันที่รับเข้ารับบริการ
                            </div>
                            <div className={classes2.redeemDetailComplete} style={{ color: '#0532D2' }}>
                                {serviceDate || ''}
                            </div>
                        </div>

                        <div className={classes2.redeemBoxComplete}>
                            <div className={classes2.redeemHeaderComplete} style={{ textAlign: 'right'}}>
                                เวลา
                            </div>
                            <div className={classes2.redeemDetailComplete} style={{ color: '#0532D2' }}>
                                {formData?.servicetime || ''}
                            </div>
                        </div>
                    </div>

                    <div className={classes2.blockBtn} style={{ marginTop: 30 }}>
                        <a className={classes2.actionRedeemBack} onClick={onCloseWindow}>กลับไปหน้าหลัก</a>
                    </div>
                </div>
            </div>
        ) : (isNext) ? (
            <div className={classes2.container}>
                <div className={classes2.container2}>
                    <div className={classes2.block} style={{ maxWidth: 450 }}>
                        <div className={classes2.title} style={{ justifyContent: 'flex-start', paddingLeft: 20, paddingTop: 30 }}>ลงทะเบียนนัดหมาย</div>
                        <div className={classes2.detail} style={{ marginBottom: 20, justifyContent: 'flex-start', paddingLeft: 20 }}>
                            เพื่อเข้ารับบริการ upgraded ฟรีทางศูนย์บริการใกล้บ้านท่าน
                        </div>
                    
                        <div className={classes2.title4}>ตารางนัดหมาย</div>

                        <div className={classes2.redeemBox}>
                            <div className={classes2.redeemHeader} style={{ fontSize: 14, padding: '0px 6px', width: '40%' }}>
                                ศูนย์บริการ
                            </div>
                            <div className={classes2.redeemDetail} style={{ fontSize: 14, padding: '0px 6px', width: '60%' }}>
                                {serviceAllList.find(f => f.serviceid === serviceId)?.facade || ''}
                            </div>
                        </div>

                        <div className={classes2.redeemBox}>
                            <div className={classes2.redeemHeader} style={{ fontSize: 14, width: '40%' }}>
                                เลือกวันที่เข้ารับบริการ
                            </div>
                            <div className={classes2.redeemDetail} style={{ fontSize: 14, padding: '0px 6px', width: '60%' }}>
                                {/* <input type="date"
                                    name="servicedate"
                                    value={formData?.servicedate}
                                    onChange={onChangeInput}
                                    // min="2022-01-24"
                                    min={moment().format('YYYY-MM-DD')}
                                    className={classes2.formDate}
                                /> */}
                                <DayPickerInput
                                    onDayChange={day => onChangeDate(day)}
                                    formatDate={formatDate}
                                    format={FORMAT_DATE}
                                    parseDate={parseDate}
                                    placeholder={FORMAT_DATE}
                                    dayPickerProps={{
                                        modifiers: {
                                        disabled: [
                                            ...disabledDateList,
                                            {
                                            before: new Date()
                                            },
                                            {
                                            after: maxDate
                                            }
                                        ]
                                        }
                                    }}
                                    value={formData.servicedate}
                                />
                                <div>

                                </div>
                                {!formData?.servicedate && (
                                    <div className={classes2.formFieldError}>กรุณาเลือกวันที่เข้ารับบริการ</div>
                                )}

                                <style>
                                    {`
                                        .DayPicker-wrapper {
                                            font-size: 12px;
                                        }
                                        
                                        .DayPickerInput {
                                            font-size: 14px;
                                        }
                                        
                                        .DayPickerInput input {
                                            width: 200px;
                                            border: 1px solid #CCCCCC;
                                            height: 30px;
                                            outline: none;
                                            padding: 0 10px;
                                            font-size: 12px;
                                            box-sizing: border-box;
                                            font-family: myFont;
                                            border-radius: 2px;
                                            background-color: #FAFAFA;
                                        }
                                    `}
                                </style>
                            </div>
                            
                        </div>

                        <div className={classes2.redeemBox}>
                            <div className={classes2.redeemHeader} style={{ fontSize: 14, width: '40%', padding: '0px 6px' }}>
                                เวลาที่เข้ารับบริการ
                            </div>
                            <div className={classes2.redeemDetail} style={{ fontSize: 14, padding: '0px 6px', width: '60%' }}>
                               <div>
                                {serviceTimeList.map((m, i) => (
                                        <div key={i} style={{ fontSize: 12, marginBottom: 10 }}>
                                            <input
                                                type="radio"
                                                id={m}
                                                name="servicetime"
                                                value={formData.servicetime}
                                                style={{ paddingTop: 10 }}
                                                checked={formData.servicetime === m}
                                                onChange={() => onChangeTime(m)}
                                            />
                                            <label for={m} style={{ marginLeft: 7, fontSize: 14 }}>{m}</label>
                                        </div>
                                    ))}
                               </div>
                               {formData.servicedate && !formData?.servicetime && (
                                    <div className={classes2.formFieldError}>กรุณาเลือกเวลาที่เข้ารับบริการ</div>
                                )}
                            </div>
                        </div>

                        <div className={classes2.blockBtn2}>
                            <a
                               
                                onClick={() => setIsNext(!isNext)}
                                style={{ display: 'inline-block', fontSize: 14 }}
                            >
                                {`< กลับไปแก้ไขข้อมูล`}
                            </a>
                            
                            <button
                                className={disabledBtn2 ? clsx(classes2.actionRedeem, classes2.actionRedeemDisabled) : classes2.actionRedeem}
                                onClick={onSubmit}
                                disabled={disabledBtn2}
                                style={{ display: 'inline-block' }}
                            >ตกลง</button>
                        </div>
                    </div>
                </div>
            </div>
        ) :
        <div className={classes2.container}>
            <div className={classes2.container2}>
                <div className={classes2.block} style={{ maxWidth: 450 }}>
                    <div className={classes2.title}>ลงทะเบียนเพื่อรับการอัพเกรดฟรี</div>
                    <div className={classes2.detail} style={{ marginBottom: 20 }}>
                        กรุณาตรวจสอบข้อมูลของท่าน (สามารถแก้ไขได้)
                    </div>

                    <div className={classes2.redeemBox}>
                        <div className={classes2.redeemHeader}>
                            ชื่อ-นามสกุล
                            <span className={classes2.req}>*</span>
                        </div>
                        <div className={classes2.redeemDetail}>
                            <div>
                                <input className={classes2.formFieldInput} 
                                    type="text"
                                    name="customername"
                                    value={formData?.customername}
                                    onChange={onChangeInput}
                                    onBlur={onBlur}
                                    disabled={disabledEdit}
                                />
                            </div>
                            {!formData?.customername && (
                                <div className={classes2.formFieldError}>กรุณาชื่อ-นามสกุล</div>
                            )}
                        </div>
                    </div>

                    <div className={classes2.redeemBox}>
                        <div className={classes2.redeemHeader}>
                            เบอร์มือถือ
                            <span className={classes2.req}>*</span>
                        </div>
                        <div className={classes2.redeemDetail}>
                            <div>
                                <input className={classes2.formFieldInput} 
                                    type="text"
                                    name="mobile"
                                    value={formData?.mobile}
                                    onChange={onChangeInput}
                                    onBlur={onBlur}
                                    disabled={disabledEdit}
                                    maxLength={10}
                                    onInput={e => {
                                        e.target.value = !Number.isInteger(
                                          parseInt(e.target.value)
                                        )
                                          ? ''
                                          : ~[
                                              '0',
                                              '1',
                                              '2',
                                              '3',
                                              '4',
                                              '5',
                                              '6',
                                              '7',
                                              '8',
                                              '9'
                                            ].indexOf(
                                              e.target.value[e.target.value.length - 1]
                                            )
                                          ? e.target.value
                                          : e.target.value.slice(
                                              0,
                                              e.target.value.length - 1
                                            );
                                    }}
                                />
                            </div>
                            {!formData?.mobile && (
                                <div className={classes2.formFieldError}>กรุณาเบอร์มือถือ</div>
                            )}
                            {formData?.mobile && formData?.mobile.length !== 10 && (
                                <div className={classes2.formFieldError}>กรอกเบอร์มือถือ 10 หลัก</div>
                            )}
                        </div>
                    </div>

                    <div className={classes2.redeemBox}>
                        <div className={classes2.redeemHeader}>
                            อีเมล
                            {/* <span className={classes2.req}>*</span> */}
                        </div>
                        <div className={classes2.redeemDetail}>
                            <div>
                                <input className={classes2.formFieldInput} 
                                    type="text"
                                    name="email"
                                    value={formData?.email}
                                    onChange={onChangeInput}
                                    onBlur={onBlur}
                                    disabled={disabledEdit}
                                />
                            </div>
                            {/* {!formData?.email && (
                                <div className={classes2.formFieldError}>กรุณาอีเมล</div>
                            )} */}
                            {formData?.email && !(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData?.email)) && (
                                <div className={classes2.formFieldError}>อีเมลไม่ถูกต้อง</div>
                            )}
                        </div>
                    </div>

                    <div className={classes2.redeemBox}>
                        <div className={classes2.redeemHeader}>
                            ที่อยู่
                            <span className={classes2.req}>*</span>
                        </div>
                        <div className={classes2.redeemDetail}>
                            <div>
                                <input className={classes2.formFieldInput} 
                                    type="text"
                                    name="address"
                                    value={formData?.address}
                                    onChange={onChangeInput}
                                    onBlur={onBlur}
                                    disabled={disabledEdit}
                                />
                            </div>
                            {!formData?.address && (
                                <div className={classes2.formFieldError}>กรุณาที่อยู่</div>
                            )}
                        </div>
                    </div>

                    <div className={classes2.redeemBox}>
                        <div className={classes2.redeemHeader}>
                            ตำบล / แขวง
                            <span className={classes2.req}>*</span>
                        </div>
                        <div className={classes2.redeemDetail}>
                            <div>
                                <input className={classes2.formFieldInput} 
                                    type="text"
                                    name="tumbon"
                                    value={formData?.tumbon}
                                    onChange={onChangeInput}
                                    onBlur={onBlur}
                                    disabled={disabledEdit}
                                />
                            </div>
                            {!formData?.tumbon && (
                                <div className={classes2.formFieldError}>กรุณาตำบล / แขวง</div>
                            )}
                        </div>
                    </div>

                    <div className={classes2.redeemBox}>
                        <div className={classes2.redeemHeader}>
                            อำเภอ / เขต
                            <span className={classes2.req}>*</span>
                        </div>
                        <div className={classes2.redeemDetail}>
                            <div>
                                <input className={classes2.formFieldInput} 
                                    type="text"
                                    name="district"
                                    value={formData?.district}
                                    onChange={onChangeInput}
                                    onBlur={onBlur}
                                    disabled={disabledEdit}
                                />
                            </div>
                            {!formData?.district && (
                                <div className={classes2.formFieldError}>กรุณาอำเภอ / เขต</div>
                            )}
                        </div>
                    </div>

                    <div className={classes2.redeemBox}>
                        <div className={classes2.redeemHeader}>
                            จังหวัด
                            <span className={classes2.req}>*</span>
                        </div>
                        <div className={classes2.redeemDetail}>
                            <div>
                                <input className={classes2.formFieldInput} 
                                    type="text"
                                    name="province"
                                    value={formData?.province}
                                    onChange={onChangeInput}
                                    onBlur={onBlur}
                                    disabled={disabledEdit}
                                />
                            </div>
                            {!formData?.province && (
                                <div className={classes2.formFieldError}>กรุณาจังหวัด</div>
                            )}
                        </div>
                    </div>

                    <div className={classes2.redeemBox}>
                        <div className={classes2.redeemHeader}>
                            รหัสไปรษณีย์
                            <span className={classes2.req}>*</span>
                        </div>
                        <div className={classes2.redeemDetail}>
                            <div>
                                <input className={classes2.formFieldInput} 
                                    type="text"
                                    name="postal"
                                    value={formData?.postal}
                                    onChange={onChangeInput}
                                    onBlur={onBlur}
                                    disabled={disabledEdit}
                                />
                            </div>
                            {!formData?.postal && (
                                <div className={classes2.formFieldError}>กรุณารหัสไปรษณีย์</div>
                            )}
                        </div>
                    </div>

                    <div className={classes2.redeemBox}>
                        <div className={classes2.redeemHeader}>
                            หมายเลขตัวถัง
                            <span className={classes2.req}>*</span>
                        </div>
                        <div className={classes2.redeemDetail}>
                            <div>
                                <input className={classes2.formFieldInput2} 
                                    type="text"
                                    name="framenumber"
                                    value={formData?.framenumber}
                                    disabled
                                />
                            </div>
                        </div>
                    </div>

                    <div className={classes2.redeemBox}>
                        <div className={classes2.redeemHeader}>
                            เวสป้ารุ่น
                            <span className={classes2.req}>*</span>
                        </div>
                        <div className={classes2.redeemDetail}>
                            <div>
                                <input className={classes2.formFieldInput2} 
                                    type="text"
                                    name="model"
                                    value={formData?.model}
                                    disabled
                                />
                            </div>
                        </div>
                    </div>

                    <div className={classes2.redeemBox}>
                        <div className={classes2.redeemHeader}>
                            ระยะทาง
                            <span className={classes2.req}>*</span>
                        </div>
                        <div className={classes2.redeemDetail2}>
                            <div>
                                <input className={classes2.formFieldInput} 
                                    type="number"
                                    name="milage"
                                    value={formData?.milage}
                                    onChange={onChangeInput}
                                    onBlur={onBlur}
                                    onInput={e => {
                                        e.target.value = !Number.isInteger(
                                          parseInt(e.target.value)
                                        )
                                          ? ''
                                          : ~[
                                              '0',
                                              '1',
                                              '2',
                                              '3',
                                              '4',
                                              '5',
                                              '6',
                                              '7',
                                              '8',
                                              '9'
                                            ].indexOf(
                                              e.target.value[e.target.value.length - 1]
                                            )
                                          ? e.target.value
                                          : e.target.value.slice(
                                              0,
                                              e.target.value.length - 1
                                            );
                                    }}
                                />
                            </div>
                            {!formData?.milage && (
                                <div className={classes2.formFieldError}>กรุณากรอกเป็นตัวเลข</div>
                            )}
                        </div>
                        <div className={classes2.redeemDetail3}>
                            กิโลเมตร
                        </div>
                    </div>

                    <div className={classes2.redeemBox}>
                        <div className={classes2.redeemHeader}>
                            ทะเบียนรถ
                            <span className={classes2.req}>*</span>
                        </div>
                        <div className={classes2.redeemDetail}>
                            <div>
                                <input className={classes2.formFieldInput} 
                                    type="text"
                                    name="vehicleregisternumber"
                                    value={formData?.vehicleregisternumber}
                                    onChange={onChangeInput}
                                    onBlur={onBlur}
                                />
                            </div>
                            {!formData?.vehicleregisternumber && (
                                <div className={classes2.formFieldError}>กรุณาข้อมูลให้ครบ</div>
                            )}
                        </div>
                    </div>

                    <div className={classes2.redeemBox}>
                        <div className={classes2.redeemHeader2}>
                            เลือกศูนย์บริการที่ต้องการเข้า
                            <span className={classes2.req}>*</span>
                        </div>
                    </div>

                    <div>
                        <div className={classes2.redeemRadio}>
                            {serviceList.map((m, i) => (
                                <div key={i} style={{ fontSize: 12, marginBottom: 10 }}>
                                    <input
                                        type="radio"
                                        id={m.serviceid}
                                        name="serviceid"
                                        value={formData.serviceid}
                                        style={{ paddingTop: 10 }}
                                        checked={m.serviceid === formData.serviceid}
                                        onChange={() => onChangeService(m.serviceid)}
                                    />
                                    <label for={m.serviceid} style={{ marginLeft: 7 }}>{m.facade}</label>
                                </div>
                            ))}
                            {otherServiceList.length > 0 && (
                                <div style={{ fontSize: 12, marginBottom: 15 }}>
                                    <input
                                        type="radio"
                                        id={"other"}
                                        name="serviceid"
                                        value={"other"}
                                        style={{ paddingTop: 10 }}
                                        checked={'other'=== formData.serviceid}
                                        onChange={() => onChangeService('other')}
                                    />
                                    <label for={"other"} style={{ marginLeft: 7 }}>อื่นๆ</label>

                                    <div style={{ display: 'inline-block', marginLeft: 15 }}>
                                        {formData.serviceid === 'other' ? (
                                            <select
                                                // className={classes2.formFieldSelect}
                                                className='minimal'
                                                value={formData?.serviceidother}
                                                onChange={onChangeInput}
                                                onBlur={onBlur}
                                                name="serviceidother"
                                                style={{ width: 200 }}
                                            >
                                                <option hidden>เลือกศูนย์บริการ</option>
                                                {otherServiceList.map(m => (
                                                    <option value={m} key={m}>{m}</option>
                                                ))}
                                            </select>
                                        ) : (
                                            // <input className={classes2.formFieldInput} 
                                            //     style={{ width: 200 }}
                                            //     type="text"
                                            //     name="serviceidother"
                                            //     placeholder="เลือกศูนย์บริการ"
                                            //     value={formData?.serviceidother}
                                            //     onChange={onChangeInput}
                                            //     onBlur={onBlur}
                                            //     list="branchlist"
                                            //     disabled={formData.serviceid !== 'other'}
                                            // />
                                            <select
                                                className='minimal'
                                                disabled
                                                style={{ width: 200 }}
                                            >
                                                <option hidden>เลือกศูนย์บริการ</option>
                                            </select>
                                        )}
                                       
                                        {/* <input className={classes2.formFieldInput} 
                                            type="text"
                                            name="serviceidother"
                                            placeholder="เลือกศูนย์บริการ"
                                            value={formData?.serviceidother}
                                            onChange={onChangeInput}
                                            onBlur={onBlur}
                                            list="branchlist"
                                            disabled={formData.serviceid !== 'other'}
                                        />
                                        <datalist id="branchlist">
                                            {otherServiceList.map(m => (
                                                <option value={m} key={m}>{m}</option>
                                            ))}
                                        </datalist> */}
                                        {/* {(formData?.serviceidother || '').length >= 3 && (
                                            <datalist id="branchlist">
                                                {otherServiceList.map(m => (
                                                    <option value={m} key={m}>{m}</option>
                                                ))}
                                            </datalist>
                                        )} */}
                                    </div>
                                </div>
                            )}
                            {(!formData?.serviceid || (formData.serviceid === 'other' && !formData.serviceidother)) && (
                                <div className={classes2.formFieldError}>กรุณาเลือกศูนย์บริการ</div>
                            )}
                        </div>
                    </div>

                    <div className={classes2.blockBtn}>
                        {/* <button
                            className={classes2.actionRedeem}
                            onClick={() => setDisabledEdit(!disabledEdit)}
                            style={{ display: 'inline-block' }}
                        >
                            {disabledEdit ? 'แก้ไข' : 'บันทึก'}
                        </button> */}
                        
                        {isBkk ? (
                            <button
                                className={disabledBtn ? clsx(classes2.actionRedeem, classes2.actionRedeemDisabled) : classes2.actionRedeem}
                                onClick={onSubmit}
                                disabled={disabledBtn}
                                style={{ display: 'inline-block', marginLeft: 10 }}
                            >ตกลง</button>
                        ) : (
                            <button
                                className={disabledBtn ? clsx(classes2.actionRedeem, classes2.actionRedeemDisabled) : classes2.actionRedeem}
                                onClick={onClickNext}
                                disabled={disabledBtn}
                                style={{ display: 'inline-block', marginLeft: 10 }}
                            >ถัดไป</button>
                        )}
                        
                    </div>
                </div>
            </div>
        </div>
    }
    </>)
}

export default RegisterBooking;