import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import '../App.css';
import '../css/common.css';
import axios from 'axios';
import { API } from '../hooks/api';
import { makeStyles } from '@material-ui/core';
import Loading from '../components/Loading';

import BackGroundImg from '../assets/bg-desktop.jpg';
import BackGroundXsImg from '../assets/bg-mobile.jpg';

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: '#6DCDB1',
        minHeight: '100vh',
    },
    container2: {
        backgroundColor: '#6DCDB1',
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    containerbg: {
        backgroundColor: '#6DCDB1',
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        backgroundImage: `url(${BackGroundImg})`,
        backgroundPosition: '0% 0%',
        backgroundRepeat: 'no-repeat',

        backgroundSize: '100% auto',

        [theme.breakpoints.down('md')]: {
            backgroundSize: '100% 100%',
        },
        [theme.breakpoints.down('sm')]: {
            backgroundSize: '100% 70%',
        },
        [theme.breakpoints.down('xs')]: {
            backgroundImage: `url(${BackGroundXsImg})`,
            backgroundSize: '100% auto',
        }
    },
    blockbg: {
        paddingTop: 160,
        [theme.breakpoints.down('md')]: {
            paddingTop: 70,
        },
        [theme.breakpoints.down('sm')]: {
            paddingTop: 50,
        },
        [theme.breakpoints.down('xs')]: {
            paddingTop: 50,
        }
    },
    block: {
        display: 'block',
    },
    title: {
        color: 'white',
        fontWeight: 700,
        fontSize: 30,
        display: 'flex',
        justifyContent: 'center',
        textShadow: '0px 2px 2px #02855F',
        marginBottom: 15,
        fontFamily: "myFontBold"
    },
    detail: {
        color: '#444444',
        fontSize: 18,
        display: 'flex',
        justifyContent: 'center',
    },
    detail2: {
        color: '#444444',
        fontSize: 22,
        display: 'flex',
        justifyContent: 'center',
        fontFamily: "myFontBold"
    },
    blockBtn: {
        marginTop: 30,
    },
    actionRedeem: {
        background: '#000',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        textDecoration: 'none',

        fontSize: 20,
        height: 50,
        width: 170,
        borderRadius: '50px',
        margin: 'auto auto 2px',
        fontWeight: 600,
        cursor: 'pointer',
        [theme.breakpoints.down('xs')]: {
            fontSize: 18,
        }
    },
    actionRedeem2: {
        background: '#000',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        textDecoration: 'none',

        fontSize: 20,
        height: 50,
        width: 270,
        borderRadius: '50px',
        margin: 'auto auto 2px',
    },
    blockImage: {
        marginBottom: 30,
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
    },
    img: {
        display: 'flex',
        width: '100%',
        height: '100%',
        maxWidth: 200,
        
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            height: '100%',
            maxWidth: 150,
        }
    },
    blockReceive: {
        marginTop: 30,
        backgroundColor: '#FFF',
        borderRadius: 10,
        padding: '20px 10px',
    },
    title2: {
        color: '#000',
        fontWeight: 800,
        fontSize: 24,
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 15,
        fontFamily: "myFontBold"
    },
    redeemBox: {
        padding: '5px 0px',
        display: 'flex',
        flexGrow: 1,
    },
    redeemHeader: {
        padding: '0px 6px',
        width: '40%',
        textAlign: 'right',
        fontSize: 18,
        color: '#444444',
        fontFamily: "myFontBold",
        marginTop: 14,
    },
    redeemDetail: {
        fontSize: 15,
        padding: '0px 6px',
        // display: 'inline-block',
        width: '60%',
    },
    redeemDetail2: {
        color: '#0532D2',
        fontSize: 15,
        padding: '0px 6px',
        // display: 'inline-block',
        width: '60%',
        fontWeight: 600,
    },
    boxRegister: {
        padding: '10px 10px',
        // display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 15,
    },
    detail3: {
        padding: '5px 10px',
        color: '#444444',
        fontSize: 18,
        // display: 'block',
        fontFamily: "myFontBold",
        justifyContent: 'center',
    },
    formFieldInput: {
        backgroundColor: '#FAFAFA',
        border: 'none',
        borderRadius: '2px',
        boxSizing: 'border-box',
        outline: 'none',
        fontFamily: 'myFont',
        width: '100%',
        fontSize: 15,
        height: 38,
        padding: '0 20px',
        // textAlign: 'center',
        '&:focus': {
            boxShadow: 'inset 0 0 0px 2px #69cdb3',
        }
    },
    actionRedeemDisabled: {
        // opacity: 0.5,
        background: 'rgba(0,0,0,0.5)'
    },
    formFieldError: {
        color: 'red',
        textIndent: '5px',
        fontSize: 11,
        marginTop: 3,
    },
}));

const init = {
    username: '',
    password: '',
    access: '',
    appversion: '',
    osversion: '',
    device: '',
    deviceid: '',
}

const Login = () => {
    const classes2 = useStyles();
    const [loading, setLoading] = useState(false);

    const [formData, setFormData] = useState({...init});
    const [formBlur, setFormBlur] = useState({});
    const [disabledBtn, setDisabled] = useState(true);

    const onChangeInput = (e) => {
        let { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        })
        setFormBlur({
            ...formBlur,
            [name]: true
        })
    }

    const onBlur = (e) => {
        setFormBlur({
            ...formBlur,
            [e.target.name]: true
        })
    }

    useEffect(() => {
        if (formData.username && formData.password) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [formData])

    const onClickLogin = (e) => {
        e.preventDefault();
        if (!formData.username || !formData.password) {
            return
        }
        console.log(formData);
        setLoading(true);
        let apiPath = API.ADMIN_LOGIN;

        (async () => {
            const response = await axios.post(apiPath, formData);
            console.log("check", response);
            setLoading(false);
            let data = response?.data;

            if (data?.result === 'error') {
              alert('เข้าสู่ระบบไม่สำเร็จ! Username/Password ผิดพลาด')
            } else {
                let accessToken = data?.apptoken;
                localStorage.setItem('apptoken', accessToken);
                window.location = '/admin-booking';
            }     
        })(); 
    }

    return (<>
        <div className={classes2.container}>
            {loading && <Loading />}
            
            <form onSubmit={e => onClickLogin(e)}>
            <div className={classes2.containerbg}>
                <div className={classes2.blockbg} style={{ maxWidth: 450 }}>
                    <div className={classes2.detail2}>เข้าสู่ระบบข้อมูลการลงทะเบียน</div>

                    <div className={classes2.boxRegister}>

                        <div className={classes2.redeemBox}>
                            <div className={classes2.redeemHeader}>
                                Username
                            </div>
                            <div className={classes2.redeemDetail}>
                                <div className={classes2.detail3}>
                                    <input className={classes2.formFieldInput} 
                                        type="text"
                                        name="username"
                                        value={formData?.username}
                                        onChange={onChangeInput}
                                        onBlur={onBlur}
                                    />
                                </div>
                                {!formData?.username && formBlur?.username && (
                                    <div className={classes2.formFieldError}>กรุณากรอกบัญชีผู้ใช้งาน</div>
                                )}
                            </div>
                        </div>

                        <div className={classes2.redeemBox}>
                            <div className={classes2.redeemHeader}>
                                Password
                            </div>
                            <div className={classes2.redeemDetail}>
                                <div className={classes2.detail3}>
                                    <input className={classes2.formFieldInput} 
                                        type="password"
                                        name="password"
                                        value={formData?.password}
                                        onChange={onChangeInput}
                                        onBlur={onBlur}
                                    />
                                </div>
                                {!formData?.password && formBlur?.password && (
                                    <div className={classes2.formFieldError}>กรุณากรอกรหัสผ่าน</div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={classes2.blockBtn}>
                        <button
                            className={disabledBtn ? clsx(classes2.actionRedeem, classes2.actionRedeemDisabled) : classes2.actionRedeem}
                            // onClick={onClickLogin}
                            type="submit"
                            disabled={disabledBtn}
                        >เข้าสู่ระบบ</button>
                    </div>
                </div>
            </div>
            </form>
        </div>
    </>)
}

export default Login;