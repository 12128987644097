import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import '../App.css';
import '../css/common.css';
import axios from 'axios';
import { API } from '../hooks/api';
import { makeStyles } from '@material-ui/core';
import Loading from '../components/Loading';
import { availableMonths } from './helpper';

import BackGroundImg from '../assets/bg-desktop.jpg';
import BackGroundXsImg from '../assets/bg-mobile.jpg';
import UpgradeImg from '../assets/icon-01.svg';
import CompletedImg from '../assets/icon-012.svg';
import EditImg from '../assets/edit.png';

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: '#6DCDB1',
        minHeight: '100vh',
    },
    container2: {
        backgroundColor: '#6DCDB1',
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    containerbg: {
        backgroundColor: '#6DCDB1',
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        backgroundImage: `url(${BackGroundImg})`,
        backgroundPosition: '0% 0%',
        backgroundRepeat: 'no-repeat',

        backgroundSize: '100% auto',

        [theme.breakpoints.down('md')]: {
            backgroundSize: '100% 100%',
        },
        [theme.breakpoints.down('sm')]: {
            backgroundSize: '100% 70%',
        },
        [theme.breakpoints.down('xs')]: {
            backgroundImage: `url(${BackGroundXsImg})`,
            backgroundSize: '100% auto',
        }
    },
    blockbg: {
        paddingTop: 160,
        [theme.breakpoints.down('md')]: {
            paddingTop: 70,
        },
        [theme.breakpoints.down('sm')]: {
            paddingTop: 50,
        },
        [theme.breakpoints.down('xs')]: {
            paddingTop: 100,
        }
    },
    block: {
        display: 'block',
    },
    title: {
        color: 'white',
        fontWeight: 700,
        fontSize: 30,
        display: 'flex',
        justifyContent: 'center',
        textShadow: '0px 2px 2px #02855F',
        marginBottom: 15,
        fontFamily: "myFontBold",
        [theme.breakpoints.down('xs')]: {
            fontSize: 24,
        }
    },
    title5: {
        color: 'white',
        fontWeight: 700,
        fontSize: 40,
        display: 'flex',
        justifyContent: 'center',
        textShadow: '0px 2px 2px #02855F',
        marginBottom: 15,
        fontFamily: "myFontBold",
        [theme.breakpoints.down('xs')]: {
            fontSize: 32,
        }
    },
    detail: {
        color: '#444444',
        fontSize: 18,
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down('xs')]: {
            fontSize: 16,
        }
    },
    detail2: {
        color: '#444444',
        fontSize: 18,
        display: 'flex',
        justifyContent: 'center',
        fontFamily: "myFontBold",
        [theme.breakpoints.down('xs')]: {
            fontSize: 16,
        }
    },
    blockBtn: {
        marginTop: 30,
    },
    actionRedeem: {
        background: '#000',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        textDecoration: 'none',

        fontSize: 20,
        height: 50,
        width: 170,
        borderRadius: '50px',
        margin: 'auto auto 2px',
        fontWeight: 600,
        cursor: 'pointer',
        [theme.breakpoints.down('xs')]: {
            fontSize: 18,
        }
    },
    actionRedeemBack: {
        background: '#000',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        textDecoration: 'none',

        fontSize: 20,
        height: 50,
        width: 190,
        borderRadius: '50px',
        margin: 'auto auto 2px',
        fontWeight: 600,
        cursor: 'pointer',
        [theme.breakpoints.down('xs')]: {
            fontSize: 18,
        }
    },
    actionRedeem2: {
        background: '#000',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        textDecoration: 'none',

        fontSize: 20,
        height: 50,
        width: 270,
        fontWeight: 600,
        borderRadius: '50px',
        margin: 'auto auto 2px',
        cursor: 'pointer',
        [theme.breakpoints.down('xs')]: {
            fontSize: 18,
        }
    },
    blockImage: {
        marginBottom: 30,
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
    },
    img: {
        display: 'flex',
        width: '100%',
        height: '100%',
        maxWidth: 200,
        
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            height: '100%',
            maxWidth: 150,
        }
    },
    blockReceive: {
        marginTop: 30,
        backgroundColor: '#FFF',
        borderRadius: 10,
        padding: '20px 10px',
    },
    title2: {
        color: '#000',
        fontWeight: 800,
        fontSize: 24,
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 15,
        fontFamily: "myFontBold",
        [theme.breakpoints.down('xs')]: {
            fontSize: 20,
        }
    },
    redeemBox: {
        padding: '10px 0px',
        display: 'flex',
        flexGrow: 1,
    },
    redeemHeader: {
        padding: '0px 6px',
        // display: 'inline-block',
        width: '40%',
        textAlign: 'right',
        fontSize: 15,
        [theme.breakpoints.down('xs')]: {
            fontSize: 14,
        }
    },
    redeemDetail: {
        fontSize: 15,
        padding: '0px 6px',
        // display: 'inline-block',
        width: '60%',
        [theme.breakpoints.down('xs')]: {
            fontSize: 14,
        }
    },
    redeemDetail2: {
        color: '#0532D2',
        fontSize: 15,
        padding: '0px 6px',
        // display: 'inline-block',
        width: '60%',
        fontWeight: 600,
        [theme.breakpoints.down('xs')]: {
            fontSize: 14,
        }
    },
    boxRegister: {
        padding: '0px 10px',
        // display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    detail3: {
        padding: '5px 10px',
        color: '#444444',
        fontSize: 18,
        // display: 'block',
        fontFamily: "myFontBold",
        justifyContent: 'center',
        [theme.breakpoints.down('xs')]: {
            fontSize: 16,
        }
    },
    detail4: {
        // padding: '5px 10px',
        display: 'flex',
        flexGrow: 1,
    },
    detail5: {
        padding: '5px 2px',
        cursor: 'pointer',
    },
    editImg: {
        height: 37,
    },
    formFieldInput: {
        backgroundColor: '#FAFAFA',
        border: 'none',
        borderRadius: '2px',
        boxSizing: 'border-box',
        outline: 'none',
        fontFamily: 'myFont',
        width: '100%',
        fontSize: 15,
        height: 38,
        padding: '0 20px',
        textAlign: 'center',
        '&:focus': {
            boxShadow: 'inset 0 0 0px 2px #69cdb3',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 14,
        }
    },
    actionRedeemDisabled: {
        // opacity: 0.5,
        background: 'rgba(0,0,0,0.5)'
    },
    formFieldError: {
        color: 'red',
        textIndent: '5px',
        fontSize: 11,
        marginTop: 3,
    },

    title33: {
        color: 'white',
        fontWeight: 700,
        fontSize: 17,
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 7,
        fontFamily: "myFontBold",
        [theme.breakpoints.down('xs')]: {
            fontSize: 16,
        }
    },
    title34: {
        color: '#8E2F2C',
        fontSize: 20,
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 15,
        textDecoration: 'underline',
        [theme.breakpoints.down('xs')]: {
            fontSize: 18,
        }
    },
    redeemBoxComplete: {
        padding: '8px 0px',
        display: 'flex',
        flexGrow: 1,
    },
    redeemHeaderComplete: {
        padding: '0px 6px',
        width: '50%',
        fontSize: 15,
        color: '#444444',
        [theme.breakpoints.down('xs')]: {
            fontSize: 14,
        }
    },
    redeemDetailComplete: {
        fontSize: 15,
        padding: '0px 6px',
        width: '50%',
        color: '#444444',
        [theme.breakpoints.down('xs')]: {
            fontSize: 14,
        }
    },
}));

const init = {
    framenumber: '',
    enginenumber: '',
}

const Index = () => {
    const classes2 = useStyles();
    const [loading, setLoading] = useState(false);

    const [isNoUpdate, setIsNoUpdate] = useState(false);
    const [isRegister, setIsRegister] = useState(false);
    const [isReceive, setIsReceive] = useState(false);
    const [isReceive2, setIsReceive2] = useState(false);
    const [isComplete, setIsComplete] = useState(false);

    const [dataReceive, setDataReceive] = useState({
        customername: '',
        refid: "",
        facade: "",
        servicedate: "",
        servicetime: "",
    });

    const onCloseWindow = () => {
        window.location = '/';
    }

    const onClickRegister = () => {
        window.location = `/register-booking/${formData.framenumber}`
    }

    const [formData, setFormData] = useState({...init});
    const [formBlur, setFormBlur] = useState({});
    const [disabledBtn, setDisabled] = useState(true);
    const sizeframenumber = 17;

    const onChangeInput = (e) => {
        let { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: (value||'').toUpperCase()
        })
        setFormBlur({
            ...formBlur,
            [name]: true
        })

        if (value.length === sizeframenumber) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }

    const onBlur = (e) => {
        setFormBlur({
            ...formBlur,
            [e.target.name]: true
        })
    }

    const onClickCheckFrameNumber = () => {
        if (formData.framenumber.length !== sizeframenumber) {
            return
        }
        console.log(formData);
        setLoading(true);
        let apiPath = API.CUSTOMER_CHECK_SERVICE;

        (async () => {
            const response = await axios.post(apiPath, formData);
            console.log("check", response.data);
            setLoading(false);
            let data = response?.data;

            if (data?.result === 'error') {
                setIsNoUpdate(true);
            } else {
                if (data?.info?.upgraded || data?.detail === 'upgrade_done') {
                    setIsComplete(true);
                } else if (data?.detail === "found") {
                    setIsRegister(true);
                } else if (data?.detail === 'existing_register') {
                    let dateFormat = ''
                    if (data?.serviceinfo?.servicedate) {
                        var redeemdate = data?.serviceinfo?.servicedate;
                        var date = parseInt(redeemdate.split('-')[2]);
                        var month = parseInt(redeemdate.split('-')[1]); 
                        var year = parseInt(redeemdate.split('-')[0]);
                        dateFormat = `${date} ${availableMonths[month - 1]?.th || ''} ${(year||0)+543}`;
                    }

                    setDataReceive({
                        customername: data?.info?.customername,
                        refid: data?.info?.refid,
                        facade: data?.serviceinfo?.facade,
                        servicedate: dateFormat,
                        servicetime: data?.serviceinfo?.servicetime,
                        model: data?.info?.model,
                    })

                    if (data?.serviceinfo?.facade) {
                        setIsReceive(true);
                    } else {
                        setIsReceive2(true);
                    }
                } else {
                    setIsNoUpdate(true);
                }
            }
           
        })(); 
    }

    const onClickOpenFrameNumber = () => {
        window.open('https://www.youtube.com/watch?v=N_8PQ71HEsU');
    }

    const onClickBackToMain = () => {
        setIsRegister(false);
        setIsNoUpdate(false);
    }

    return (<>
    {
        isNoUpdate ? (
            <div className={classes2.container2}>
                <div className={classes2.block}>
                <div className={classes2.detail3}>หมายเลขตัวถัง</div>
                    <div className={classes2.detail4} style={{ marginBottom: 30 }}>
                        <div className={classes2.detail3}>
                            <input className={classes2.formFieldInput} 
                                type="text"
                                name="framenumber"
                                value={formData?.framenumber}
                                disabled
                            />
                            
                        </div>
                        <div className={classes2.detail5} onClick={() => onClickBackToMain()}>
                            <img src={EditImg} className={classes2.editImg} />
                        </div>
                    </div>
                    <div className={classes2.title}>รถของท่านไม่ต้อง</div>
                    <div className={classes2.title}>ทำการอัพเกรด</div>
                    <div className={classes2.blockBtn}>
                        <a className={classes2.actionRedeemBack} onClick={onCloseWindow}>กลับไปหน้าหลัก</a>
                    </div>
                </div>
            </div>
        ) : isComplete ? (
            <div className={classes2.container2}>
                <div className={classes2.block}>
                    <div className={classes2.blockImage}>
                        <img src={CompletedImg} className={classes2.img}  />
                    </div>
                    <div className={classes2.title}>รถของท่านได้ทำการ</div>
                    <div className={classes2.title}>อัพเกรดทางเทคนิค</div>
                    <div className={classes2.title}>เรียบร้อยแล้ว</div>
                    <div className={classes2.blockBtn}>
                        <a className={classes2.actionRedeemBack} onClick={onCloseWindow}>กลับไปหน้าหลัก</a>
                    </div>
                </div>
            </div>
        ) : isReceive2 ? (
            <div className={classes2.container2}>
                <div className={classes2.block}>
                    <div className={classes2.title}>ลงทะเบียนสำเร็จ</div>
                    <div className={classes2.title}>ขอบคุณ คุณ{dataReceive?.customername}</div>

                    <div style={{ marginTop: 30 }}>
                        <div className={classes2.redeemBoxComplete}>
                            <div className={classes2.redeemHeaderComplete}>
                                ลำดับการจอง
                            </div>
                            <div className={classes2.redeemDetailComplete}>
                                {dataReceive?.refid || ''}
                            </div>
                        </div>

                        <div className={classes2.redeemBoxComplete}>
                            <div className={classes2.redeemHeaderComplete}>
                                รุ่นรถ
                            </div>
                            <div className={classes2.redeemDetailComplete}>
                                {dataReceive?.model || ''}
                            </div>
                        </div>
                    </div>

                    <div className={classes2.title33} style={{ marginTop: 30 }}>ทางเวสป้า จะติดต่อกลับ</div>
                    <div className={classes2.title33}>เพื่อนัดหมายวัน-เวลา</div>
                    <div className={classes2.title33}>และศูนย์บริการที่เข้าบริการ</div>
                    <div className={classes2.title34}>ภายใน 5 วันทำการ</div>

                    <div className={classes2.blockBtn} style={{ marginTop: 40 }}>
                        <a className={classes2.actionRedeemBack} onClick={onCloseWindow}>กลับไปหน้าหลัก</a>
                    </div>
                </div>
            </div>
        ) : isReceive ? (
            <div className={classes2.container2}>
                <div className={classes2.block}>
                    <div className={classes2.title}>ท่านได้ทำการลงทะเบียน</div>
                    <div className={classes2.title}>และนัดวันเข้ารับบริการ</div>
                    <div className={classes2.title}>เรียบร้อยแล้ว</div>

                    <div className={classes2.blockReceive}>
                        <div className={classes2.title2}>ตารางนัดหมาย</div>

                        <div className={classes2.redeemBox}>
                            <div className={classes2.redeemHeader}>
                                ชื่อ
                            </div>
                            <div className={classes2.redeemDetail}>
                                {dataReceive?.customername || ''}
                            </div>
                        </div>

                        <div className={classes2.redeemBox}>
                            <div className={classes2.redeemHeader}>
                                ลำดับการจอง
                            </div>
                            <div className={classes2.redeemDetail}>
                                {dataReceive?.refid || ''}
                            </div>
                        </div>

                        <div className={classes2.redeemBox}>
                            <div className={classes2.redeemHeader}>
                                ศูนย์บริการ
                            </div>
                            <div className={classes2.redeemDetail2}>
                                {dataReceive?.facade || ''}
                            </div>
                        </div>

                        <div className={classes2.redeemBox}>
                            <div className={classes2.redeemHeader}>
                                วันที่รับเข้ารับบริการ
                            </div>
                            <div className={classes2.redeemDetail2}>
                                {dataReceive?.servicedate || ''}
                            </div>
                        </div>

                        <div className={classes2.redeemBox}>
                            <div className={classes2.redeemHeader}>
                                เวลา
                            </div>
                            <div className={classes2.redeemDetail2}>
                                {dataReceive?.servicetime || ''}
                            </div>
                        </div>
                    </div>
          
                    <div className={classes2.blockBtn}>
                        <a className={classes2.actionRedeemBack} onClick={onCloseWindow}>กลับไปหน้าหลัก</a>
                    </div>
                </div>
            </div>
        ) : isRegister ? (
            <div className={classes2.container2}>
                <div className={classes2.block}>
                    <div className={classes2.blockImage}>
                        <img src={UpgradeImg} className={classes2.img}  />
                    </div>
                    <div className={classes2.boxRegister} style={{ marginBottom: 20 }}>
                        <div className={classes2.detail3} style={{ marginTop: 20 }}>หมายเลขตัวถัง</div>
                        <div className={classes2.detail4}>
                            <div className={classes2.detail3}>
                                <input className={classes2.formFieldInput} 
                                    type="text"
                                    name="framenumber"
                                    value={formData?.framenumber}
                                    disabled
                                />
                                
                            </div>
                            <div className={classes2.detail5} onClick={() => onClickBackToMain()}>
                                <img src={EditImg} className={classes2.editImg} />
                            </div>
                        </div>
                       
                    </div>
                    <div className={classes2.title}>รถของท่านได้อยู่ในกลุ่มที่</div>
                    <div className={classes2.title}>ต้องอัพเกรดทางเทคนิค</div>
                    <div className={classes2.detail} style={{ marginTop: 30 }}>กรุณาลงทะเบียนเพื่อการนัดหมาย</div>
                    <div className={classes2.blockBtn}>
                        <a className={classes2.actionRedeem2} onClick={onClickRegister}>ลงทะเบียนเพื่อนัดหมาย</a>
                    </div>
                </div>
            </div>
        ) :
        <div className={classes2.container}>
            {loading && <Loading />}

            <div className={classes2.containerbg}>
                <div className={classes2.blockbg} style={{ maxWidth: 450 }}>
                    <div className={classes2.blockImage}>
                        {/* <img src={CompletedImg} className={classes2.img}  /> */}
                    </div>
                    <div className={classes2.title5} style={{ marginTop: 40 }}>การอัพเกรด</div>
                    <div className={classes2.title5}>ทางเทคนิค</div>
                    <div className={classes2.detail} style={{ marginTop: 30 }}>กรุณากรอกหมายเลขตัวถังรถเวสป้าของท่าน</div>
                    <div className={classes2.detail} style={{ marginTop: 10 }}>เพื่อตรวจสอบการอัพเกรดทางเทคนิค</div>
                    <div className={classes2.detail2} style={{ marginTop: 10, textDecoration: 'underline', cursor: 'pointer' }} onClick={onClickOpenFrameNumber}>
                        วิธีการการดูหมายเลขตัวถัง 17 หลัก
                    </div>
                    <div className={classes2.boxRegister}>
                        <div className={classes2.detail3} style={{ marginTop: 20 }}>หมายเลขตัวถัง</div>
                        <div className={classes2.detail3}>
                            <input className={classes2.formFieldInput} 
                                type="text"
                                name="framenumber"
                                value={formData?.framenumber}
                                onChange={onChangeInput}
                                onBlur={onBlur}
                                maxLength={sizeframenumber}
                            />
                        </div>
                        {!formData?.framenumber && formBlur?.framenumber && (
                            <div className={classes2.formFieldError}>กรุณากรอกเลขตัวถัง</div>
                        )}
                        {formData?.framenumber && (
                            <>
                            {!(/^[a-zA-Z0-9]+$/.test(formData?.framenumber)) ? (
                                <div className={classes2.formFieldError}>เลขตัวถังไม่ถูกต้อง</div>
                            ) 
                            : formData?.framenumber.length < sizeframenumber ? (
                                <div className={classes2.formFieldError}>ความยาว 17 หลัก</div>
                            ) 
                            : null}
                            </>
                        )}
                    </div>
                    <div className={classes2.blockBtn}>
                        <a
                            className={disabledBtn ? clsx(classes2.actionRedeem, classes2.actionRedeemDisabled) : classes2.actionRedeem}
                            onClick={onClickCheckFrameNumber}
                            disabled={disabledBtn}
                        >เช็คเลย!</a>
                    </div>
                </div>
            </div>
        </div>
    }
    </>)
}

export default Index;