import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Index from './page';
import RegisterBooking from './page/RegisterBooking';
import Login from './page/login';
import AdminBooking from './page/AdminBooking';

function App() {

  return (
    <Router>
      <Switch>
        <Route path="/" component={Index} exact={true} />
        <Route path="/register-booking/:id" component={RegisterBooking} exact={true} />
        <Route path="/login" component={Login} exact={true} />
        <Route path="/admin-booking" component={AdminBooking} exact={true} />
      </Switch>
    </Router>
  );
}

export default App;
