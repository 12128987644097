import React from 'react';
import '../App.css';
import '../css/admin-booking.css';
import {
    makeStyles,
    Dialog,
    Box,
    IconButton,
    SvgIcon,
} from '@material-ui/core';
import {
    X as CloseIcon,
    Edit as EditIcon,
} from 'react-feather';

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: '#FFF',
        minHeight: '100vh',
    },
    container2: {
        padding: 20,
        display: 'flex',
        justifyContent: 'center',
        marginTop: 30,
    },
    box: {
        width: '100%',
        maxWidth: 1400,
        // height: 200,
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            overflowX:'scroll'
        }
    },

    dialog: {
        padding: 20,
    },
    dialogTitle: {
        fontSize: 16,
        fontWeight: 600,
    },
    dialogDetail: {
        fontSize: 14,
    },
    boxIcon: {
        position: 'absolute',
        top: 8,
        right: 10,
    },
    closeIcon: {
        color: '#000',
        fontSize: 20,
    },
    dialogContent: {
        padding: '20px 10px 10px 10px',
    },

    blockBtn: {
        marginTop: 20,
        // display: 'flex',
        textAlign: 'center',
        justifyContent: 'center',
    },
    actionRedeem: {
        background: '#000',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        textDecoration: 'none',

        fontSize: 16,
        height: 36,
        width: 100,
        borderRadius: '50px',
        margin: 'auto auto 2px',
        cursor: 'pointer',
    },
    actionRedeemDisabled: {
        // opacity: 0.5,
        background: 'rgba(0,0,0,0.5)'
    },
}));

const SuccessDialog = ({
    open,
    onClose,
    text,
}) => {
    const classes = useStyles();

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="xs"
            fullWidth
        >
            <div className={classes.dialog}>
                <Box align="right" className={classes.boxIcon}>
                    <IconButton onClick={onClose}>
                        <SvgIcon className={classes.closeIcon}>
                            <CloseIcon />
                        </SvgIcon>
                    </IconButton>
                </Box>
                <div className={classes.dialogTitle}>
                    
                </div>

                <div className={classes.dialogContent}>
                    <div className={classes.dialogTitle}>
                        {text}
                    </div>

                    <div className={classes.blockBtn}>
                        <button
                            className={classes.actionRedeem}
                            onClick={onClose}
                            style={{ display: 'inline-block', marginLeft: 10 }}
                        >ปิด</button>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default SuccessDialog;