export const availableMonths = [
    {
        key: 1,
        th: "มกราคม",
        en: "January",
    },
    {
        key: 2,
        th: "กุมภาพันธ์",
        en: "February",
    },
    {
        key: 3,
        th: "มีนาคม",
        en: "March",
    },
    {
        key: 4,
        th: "เมษายน",
        en: "April	",
    },
    {
        key: 5,
        th: "พฤษภาคม",
        en: "May",
    },
    {
        key: 6,
        th: "มิถุนายน",
        en: "June",
    },
    {
        key: 7,
        th: "กรกฎาคม",
        en: "July",
    },
    {
        key: 8,
        th: "สิงหาคม",
        en: "August",
    },
    {
        key: 9,
        th: "กันยายน",
        en: "September",
    },
    {
        key: 10,
        th: "ตุลาคม",
        en: "October",
    },
    {
        key: 11,
        th: "พฤศจิกายน",
        en: "November",
    },
    {
        key: 12,
        th: "ธันวาคม",
        en: "December",
    },
]

export function getDateList(min = 1, max = 31){
    var list = [];
    for(var i = min; i <= max; i++) {
        list.push(i)
    }
    return list;
}

export function getYearList(){
    var list = [];
    var year = new Date().getFullYear();
    for(var i = year; i > year - 100; i--) {
        list.push({
            th: i + 543,
            en: i
        })
    }
    return list;
}

export function zeroPadding(num, size) {
    var s = "000000000" + num;
    return s.substr(s.length - size);
}

export function humanExpireTime(string){
    if (!string) return '';
    const dateString = string.split(" ")[0];
    const year = parseInt(dateString.split("-")[0], 10) + 543;
    const month = availableMonths[parseInt(dateString.split("-")[1] - 1, 10)].th;
    const date = parseInt(dateString.split("-")[2], 10);

    return `${date} ${month} ${year}`;
}

export function dateExport(string){
    if (!string) return '';
    const dateString = string.split(" ")[0];
    const year = parseInt(dateString.split("-")[0], 10) + 543;
    const month = availableMonths[parseInt(dateString.split("-")[1] - 1, 10)].th;
    const date = parseInt(dateString.split("-")[2], 10);

    return dateString;
}

export function encode(param){
    const jsonText = JSON.stringify(param);
    return btoa(
        encodeURIComponent(jsonText).replace(
            /%([0-9A-F]{2})/g,
            function toSolidBytes(match, p1) {
                return String.fromCharCode("0x" + p1);
            }
        )
    );
}

export function getAppVersion(){
    return "v.1";
}

export function getAccess(){
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return "tablet";
    }
    if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)
    ) {
        return "mobile";
    } 
    return "desktop";
}

export function getDevice(){
    var userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
    windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
    iosPlatforms = ['iPhone', 'iPad', 'iPod'],
    os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'mac';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = 'ios';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'windows';
    } else if (/Android/.test(userAgent)) {
        os = 'android';
    } else if (!os && /Linux/.test(platform)) {
        os = 'linux';
    }

    return os;
}